import React from 'react';
import StoreDetails from './details/StoreDetails';
import {Outlet, Route, Routes} from 'react-router-dom';
import RenderNavigationTabs from '../../components/RenderNavigationTabs';

function StoreDetailsIndex(props) {
    return (
       <Routes>
           <Route element={<Container />}>
                <Route element={<StoreDetails/>}/>
           </Route>
       </Routes>
    );
}

function Container(){
    return <>
        <div className={"mt-2"}>
            <RenderNavigationTabs options={[
                ['','Details',true]
            ]} />
            <Outlet />
        </div>

    </>
}

export default StoreDetailsIndex;
