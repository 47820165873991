import React, {useEffect, useMemo, useState} from 'react';
import {usePreloadedQuery, useQueryLoader} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Skeleton from "react-loading-skeleton";
import FormGroup from "../../components/form/FormGroup";
import LazyRangePicker from "../../components/datepickers/LazyRangePicker";
import useInputState from "../../components/hooks/useInputState";
import useQuery from "../../components/hooks/useQuery";
import UAParser from "ua-parser-js";
import GraphQLLegacyPagination from "../../components/pagination/GraphQLLegacyPagination";
import BasicTable from "../../components/BasicTable";
import {FormattedTime} from "react-intl";
import RenderDate from "../../components/RenderDate";
import {HomeIcon, XIcon} from "../../components/Icons";
import RenderAmount from "../../components/app-components/RenderAmount";

const query = graphql`
    query TrafficPageQuery($skip: Int, $limit: Int, $startDate: Date, $endDate: Date, $converted:Boolean){
        traffic(skip: $skip, first: $limit, startDate: $startDate, endDate: $endDate, converted: $converted){
            edges{
                node{
                    createdAt
                    landingPage
                    subID
                    userAgent
                    site{
                        website
                        name
                    }
                    order{
                        number
                        amount
                        commission
                        currency
                        status
                    }
                    ipAddress
                }
            }
           ...GraphQLLegacyPagination_legacyPageInfo
        }
    }
`

function TrafficPage(props) {
    const [queryRef, loader, dispose] = useQueryLoader(query);
    const [dates, setDates] = useState();
    const [converted, setConverted] = useInputState();
    const urlQuery = useQuery();
    useEffect(()=>{
        const limit = urlQuery.limit || 10;
        loader({
            startDate: dates && dates.length > 0 ? dates[0] : null,
            endDate: dates && dates.length > 1 ? dates[1] : null,
            converted: converted ? converted === "true" ? true : false : null,
            skip: urlQuery.page ? Number(urlQuery.page * limit) : 0,
            first: limit ? Number(limit) : null,
        },{fetchPolicy: 'network-only'})
        return dispose;
    },[dates, converted, urlQuery.limit, urlQuery.page])
    if(!queryRef) return null;
    return (
        <>
            <div className="d-flex justify-content-between align-items-start">
                <h1>Traffic</h1>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <FormGroup label={"Dates"}>
                        <LazyRangePicker value={dates} onChange={setDates}/>
                    </FormGroup>
                </div>
                <div className="col-md-6">
                    <FormGroup label="Converted">
                        <select className="form-control" value={converted} onChange={setConverted}>
                            <option />
                            <option value={true}>Show only converted</option>
                            <option value={false}>Show unconverted</option>
                        </select>
                    </FormGroup>
                </div>
            </div>
            <React.Suspense fallback={<Skeleton count={25}/>}>
                <RenderTable queryRef={queryRef}/>
            </React.Suspense>
        </>
    );
}

function RenderTable({queryRef}){
    const data = usePreloadedQuery(query, queryRef)
    const columns = useMemo(()=>{
        return [
            {
                Header:'Date',
                accessor:'createdAt',
                Cell:({value})=><div><p>
                    <FormattedTime value={value} />
                </p>
                    <small>
                        <RenderDate value={value}/>
                    </small>
                </div>

            },
            {
                Header:'Landing page',
                accessor:(originalRow, rowIndex)=>{
                    const {landingPage, site} = originalRow;
                    return {landingPage, site}
                },
                Cell:({value})=>{
                    const {landingPage, site} = value
                    try{
                        const u = new URL(landingPage)
                        return <div>
                            <p>{u.pathname === "/" ? <HomeIcon/>:u.pathname}</p>
                            <small>{site.website}</small>
                        </div>
                    }catch(e){

                    }
                    return 'n/a'
                }
            },
            {
                Header:'SUB ID',
                accessor:'subID',
            },
            {
                Header:'Visitor',
                accessor:(originalRow, rowIndex)=>{
                    const {userAgent, ipAddress} = originalRow;
                    return {userAgent, ipAddress}
                },
                Cell:({value})=>{
                    const  {userAgent, ipAddress} = value;
                    return <RenderVisitor user_agent={userAgent} ip_address={ipAddress}/>
                }
            },
            {
                Header:'Conversion',
                accessor:(originalRow)=>{
                    const {number, amount, commission, currency, status} = originalRow.order || {};
                    return  {number, amount, commission, currency, status}
                },
                Cell:({value})=>{
                    const  {number, amount, commission, currency, status} = value;if(!number || status !== "APPROVED"){
                        return <XIcon/>
                    }
                    return  <div>
                        <p className="text-success">
                            <RenderAmount value={commission} currency={currency} />
                        </p>
                        <small>{number} = <RenderAmount value={amount} currency={currency}/></small>
                    </div>

                }
            },
        ]
    }, [])
    return <>
        {
            data.traffic.edges.length ? <>
                    <BasicTable
                        columns={columns}
                        data={data.traffic.edges.map(({node})=>node)}
                    />
                    <GraphQLLegacyPagination fragmentData={data.traffic}/>
            </> : <p className={"text-center"}>No traffic data</p>
        }
    </>
}



function RenderVisitor({user_agent, ip_address}){
    const ua = new UAParser(user_agent);
    return <div>
        <p>{ua.getOS().name +" ~ "+ua.getBrowser().name}</p>
        <small>{ip_address}</small>
    </div>
}

function RenderQueryParam({value, param}){
    try{
        const u = new URL(value)
        return u.searchParams.get(param)
    }catch(e){

    }
    return ''
}


export default TrafficPage;
