import Loadable from 'react-loadable';
import PropTypes from 'prop-types';
import LazyLoadingBar from "../Spinner";

const CSVReaderInputLazy = Loadable({
    loader: () => import('./CSVReaderInputComponent'),
    loading: LazyLoadingBar,

})


CSVReaderInputLazy.propTypes ={
    onResult: PropTypes.func.isRequired,
    onLoadStart: PropTypes.func
}


export default CSVReaderInputLazy
