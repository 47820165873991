/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AgencyOrdersGraphQL_orderItem$ref = any;
type GraphQLLegacyPagination_legacyPageInfo$ref = any;
export type AgencyOrdersGraphQLQueryVariables = {|
  limit?: ?number,
  offset?: ?number,
  siteId?: ?string,
  startDate?: ?any,
  endDate?: ?any,
|};
export type AgencyOrdersGraphQLQueryResponse = {|
  +orders: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +$fragmentRefs: AgencyOrdersGraphQL_orderItem$ref
      |}
    |}>,
    +$fragmentRefs: GraphQLLegacyPagination_legacyPageInfo$ref,
  |}
|};
export type AgencyOrdersGraphQLQuery = {|
  variables: AgencyOrdersGraphQLQueryVariables,
  response: AgencyOrdersGraphQLQueryResponse,
|};
*/


/*
query AgencyOrdersGraphQLQuery(
  $limit: Int
  $offset: Int
  $siteId: ID
  $startDate: DateTime
  $endDate: DateTime
) {
  orders(first: $limit, skip: $offset, siteId: $siteId, startDate: $startDate, endDate: $endDate) {
    edges {
      node {
        ...AgencyOrdersGraphQL_orderItem
        id
      }
    }
    ...GraphQLLegacyPagination_legacyPageInfo
  }
}

fragment AgencyOrdersGraphQL_orderItem on Order {
  createdAt
  amount
  currency
  commission
  number
  status
  site {
    website
    name
    id
  }
}

fragment GraphQLLegacyPagination_legacyPageInfo on GraphQLPagination {
  __isGraphQLPagination: __typename
  legacyPageInfo {
    count
    offset
    limit
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "offset"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "siteId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v5 = [
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "endDate"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "siteId",
    "variableName": "siteId"
  },
  {
    "kind": "Variable",
    "name": "skip",
    "variableName": "offset"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "startDate"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AgencyOrdersGraphQLQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "OrdersConnection",
        "kind": "LinkedField",
        "name": "orders",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrdersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Order",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "AgencyOrdersGraphQL_orderItem"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GraphQLLegacyPagination_legacyPageInfo"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AgencyOrdersGraphQLQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "OrdersConnection",
        "kind": "LinkedField",
        "name": "orders",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrdersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Order",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "commission",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "number",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Site",
                    "kind": "LinkedField",
                    "name": "site",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "website",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LegacyPageInfo",
                "kind": "LinkedField",
                "name": "legacyPageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "count",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "offset",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "limit",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "GraphQLPagination",
            "abstractKey": "__isGraphQLPagination"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e10b028e9751f35df6d1c2f94a86bdf8",
    "id": null,
    "metadata": {},
    "name": "AgencyOrdersGraphQLQuery",
    "operationKind": "query",
    "text": "query AgencyOrdersGraphQLQuery(\n  $limit: Int\n  $offset: Int\n  $siteId: ID\n  $startDate: DateTime\n  $endDate: DateTime\n) {\n  orders(first: $limit, skip: $offset, siteId: $siteId, startDate: $startDate, endDate: $endDate) {\n    edges {\n      node {\n        ...AgencyOrdersGraphQL_orderItem\n        id\n      }\n    }\n    ...GraphQLLegacyPagination_legacyPageInfo\n  }\n}\n\nfragment AgencyOrdersGraphQL_orderItem on Order {\n  createdAt\n  amount\n  currency\n  commission\n  number\n  status\n  site {\n    website\n    name\n    id\n  }\n}\n\nfragment GraphQLLegacyPagination_legacyPageInfo on GraphQLPagination {\n  __isGraphQLPagination: __typename\n  legacyPageInfo {\n    count\n    offset\n    limit\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eceb74eb247dae4b5a7cf960e7a723b6';

module.exports = node;
