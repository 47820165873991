import  create from 'zustand'
import {fetchGraphQL} from '../RelayEnvironment';
const profileFields = ` id
                referenceId
                name
                profilePhoto {
                  url
                }
                firstName
                lastName
                email
                referralCode
                companyName
                website
                socials {
                  id
                  platform
                  url
                }
                address {
                  addressLine1
                  addressLine2
                  country
                  city
                  postalCode
                  state
                }
                isAccountPublic`
const useProfile = create((set, get)=>{
  return {
    profile:null,
    httpGetProfile:()=>{
      return fetchGraphQL(`
      query SettingsIndexQuery {
        me {
          ${profileFields}
        }
      }`).then(({data})=>{
        console.log(data)
        set({
          profile: data?.me
        })
      })
    },
    deleteApiToken:(token)=>{
      return fetchGraphQL(`
        mutation DeleteTokenButtonMutation($token: ID!){
            deleteApiKey(id: $token)
        }
      `, {
        token
      }).then(({data})=>{
        get().httpGetProfile()
        return data.deleteApiKey
      })
    },
    httpUpdateProfile:(change)=>{
      set({
        profile: {...get().profile, ...change}
      })
      return fetchGraphQL(`
          mutation SocialProfileMutation($profile: ProfileInput!){
              updateProfile(profile: $profile){
                ${profileFields}
              }
          }
      `, {
        profile: change
      }).then(({data})=>{
        set({profile: data.updateProfile})
      })
    },
  }
})
export default useProfile;
