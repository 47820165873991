/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type GraphQLLegacyPagination_legacyPageInfo$ref = any;
type MyStores_site$ref = any;
export type AccountStatus = "Approved" | "Blocked" | "Invited" | "Pending" | "%future added value";
export type MyStoresQueryVariables = {|
  accountStatus?: ?AccountStatus,
  offset?: ?number,
  limit?: ?number,
|};
export type MyStoresQueryResponse = {|
  +mySites: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +$fragmentRefs: MyStores_site$ref,
      |}
    |}>,
    +$fragmentRefs: GraphQLLegacyPagination_legacyPageInfo$ref,
  |}
|};
export type MyStoresQuery = {|
  variables: MyStoresQueryVariables,
  response: MyStoresQueryResponse,
|};
*/


/*
query MyStoresQuery(
  $accountStatus: AccountStatus
  $offset: Int
  $limit: Int
) {
  mySites(accountStatus: $accountStatus, skip: $offset, first: $limit) {
    edges {
      node {
        id
        ...MyStores_site
      }
    }
    ...GraphQLLegacyPagination_legacyPageInfo
  }
}

fragment GraphQLLegacyPagination_legacyPageInfo on GraphQLPagination {
  __isGraphQLPagination: __typename
  legacyPageInfo {
    count
    offset
    limit
  }
}

fragment MyStores_site on MySite {
  id
  site {
    name
    website
    domain
    referenceId
    affiliatePortal
    id
  }
  referralCodes
  couponCodes {
    code
    discountType
    discountValue
  }
  accountStatus
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountStatus"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "offset"
},
v3 = [
  {
    "kind": "Variable",
    "name": "accountStatus",
    "variableName": "accountStatus"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "skip",
    "variableName": "offset"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MyStoresQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "MySitesConnection",
        "kind": "LinkedField",
        "name": "mySites",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MySiteEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MySite",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "MyStores_site"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GraphQLLegacyPagination_legacyPageInfo"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "MyStoresQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "MySitesConnection",
        "kind": "LinkedField",
        "name": "mySites",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MySiteEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MySite",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Site",
                    "kind": "LinkedField",
                    "name": "site",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "website",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "domain",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "referenceId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "affiliatePortal",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "referralCodes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CouponCode",
                    "kind": "LinkedField",
                    "name": "couponCodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "code",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "discountType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "discountValue",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accountStatus",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LegacyPageInfo",
                "kind": "LinkedField",
                "name": "legacyPageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "count",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "offset",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "limit",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "GraphQLPagination",
            "abstractKey": "__isGraphQLPagination"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d2bbc27df487acf701bc4463da853a25",
    "id": null,
    "metadata": {},
    "name": "MyStoresQuery",
    "operationKind": "query",
    "text": "query MyStoresQuery(\n  $accountStatus: AccountStatus\n  $offset: Int\n  $limit: Int\n) {\n  mySites(accountStatus: $accountStatus, skip: $offset, first: $limit) {\n    edges {\n      node {\n        id\n        ...MyStores_site\n      }\n    }\n    ...GraphQLLegacyPagination_legacyPageInfo\n  }\n}\n\nfragment GraphQLLegacyPagination_legacyPageInfo on GraphQLPagination {\n  __isGraphQLPagination: __typename\n  legacyPageInfo {\n    count\n    offset\n    limit\n  }\n}\n\nfragment MyStores_site on MySite {\n  id\n  site {\n    name\n    website\n    domain\n    referenceId\n    affiliatePortal\n    id\n  }\n  referralCodes\n  couponCodes {\n    code\n    discountType\n    discountValue\n  }\n  accountStatus\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5bf7c1587a929bb742ac1f177efea182';

module.exports = node;
