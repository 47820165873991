/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type RecentOrdersGraphQL_orders$ref = any;
type RecentPayoutsGraphQL_payouts$ref = any;
export type AgencyHomeQueryVariables = {||};
export type AgencyHomeQueryResponse = {|
  +$fragmentRefs: RecentOrdersGraphQL_orders$ref & RecentPayoutsGraphQL_payouts$ref
|};
export type AgencyHomeQuery = {|
  variables: AgencyHomeQueryVariables,
  response: AgencyHomeQueryResponse,
|};
*/


/*
query AgencyHomeQuery {
  ...RecentOrdersGraphQL_orders
  ...RecentPayoutsGraphQL_payouts
}

fragment RecentOrdersGraphQL_listItem on Order {
  id
  number
  amount
  commission
  createdAt
  currency
  status
  site {
    name
    website
    id
  }
}

fragment RecentOrdersGraphQL_orders on Query {
  orders(first: 5) {
    edges {
      node {
        ...RecentOrdersGraphQL_listItem
        id
      }
    }
  }
}

fragment RecentPayoutsGraphQL_listItem on Payout {
  id
  createdAt
  amount
  note
  paymentMethod
  site {
    name
    website
    currency
    id
  }
}

fragment RecentPayoutsGraphQL_payouts on Query {
  payouts(first: 5) {
    edges {
      node {
        ...RecentPayoutsGraphQL_listItem
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 5
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "website",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AgencyHomeQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "RecentOrdersGraphQL_orders"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "RecentPayoutsGraphQL_payouts"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AgencyHomeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "OrdersConnection",
        "kind": "LinkedField",
        "name": "orders",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrdersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Order",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "number",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "commission",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Site",
                    "kind": "LinkedField",
                    "name": "site",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "orders(first:5)"
      },
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "PayoutsConnection",
        "kind": "LinkedField",
        "name": "payouts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PayoutsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Payout",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v3/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "note",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "paymentMethod",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Site",
                    "kind": "LinkedField",
                    "name": "site",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v4/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "payouts(first:5)"
      }
    ]
  },
  "params": {
    "cacheID": "97982b03e4977fc645b00e7e232449ed",
    "id": null,
    "metadata": {},
    "name": "AgencyHomeQuery",
    "operationKind": "query",
    "text": "query AgencyHomeQuery {\n  ...RecentOrdersGraphQL_orders\n  ...RecentPayoutsGraphQL_payouts\n}\n\nfragment RecentOrdersGraphQL_listItem on Order {\n  id\n  number\n  amount\n  commission\n  createdAt\n  currency\n  status\n  site {\n    name\n    website\n    id\n  }\n}\n\nfragment RecentOrdersGraphQL_orders on Query {\n  orders(first: 5) {\n    edges {\n      node {\n        ...RecentOrdersGraphQL_listItem\n        id\n      }\n    }\n  }\n}\n\nfragment RecentPayoutsGraphQL_listItem on Payout {\n  id\n  createdAt\n  amount\n  note\n  paymentMethod\n  site {\n    name\n    website\n    currency\n    id\n  }\n}\n\nfragment RecentPayoutsGraphQL_payouts on Query {\n  payouts(first: 5) {\n    edges {\n      node {\n        ...RecentPayoutsGraphQL_listItem\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '79d6f545ce0421acedff1bdca4a97975';

module.exports = node;
