import React, {Component} from 'react';
import SignatureInput from "./SignatureInput";
import CountrySelector from "../form/CountrySelector";
import LazyMultipleSelector from "../multiple-selector/LazyMultipleSelector";
import RadioGroup from "./RadioGroup";
import SimpleFileUpload from "./SimpleFileUpload";
import Checkbox from "./Checkbox";
import {FormattedMessage} from "react-intl";
import CurrencySelector from "./CurrencySelector";
import Label from "./Label";

class RenderFormInput extends Component {

    keys = ['isRequired', 'label','value','placeholder','id','prepend','type','size','options','onChange']

    shouldComponentUpdate(nextProps){
        return !!this.keys.find((key)=>{
            return this.props[key] !== nextProps[key]
        })
    }

    onChange = (id)=>e=>{
        this.props.onChange(id)(e)
    }


    render() {
        const { isRequired, label, value, placeholder, id, prepend, append, type, size, options } = this.props;
        const onChange = this.onChange(id)
        let inputCmp = null;
        switch (type) {
            case "signature":
                inputCmp =  <SignatureInput onChange={onChange} value={value} />
                break;
            case 'textarea':
                inputCmp = (<textarea id={id} name={fieldNameMapper[id] || label}
                                      value={value} onInput={onChange}
                                      className={'form-control'}
                />);
                break;
            case 'country':
                inputCmp = (
                    <CountrySelector value={value} onChange={onChange} />
                );
                break;
            case 'select':
                const opts = typeof options === "string" ?
                    options.split('\n').map((item) => item.trim()).filter((i) => i)
                        .map((item) => <option value={item}>{item}</option>)
                    : options.map((item)=><option value={item.value}>{item.label}</option>)
                inputCmp = (
                    options &&
                    <select className={'form-control'} value={value} id={id} name={label} onChange={onChange}>
                        <option />
                        {opts}
                    </select>
                );
                break;
            case "multiple-select":
            case "multiplSelect":
                const va = value && value.map((val)=>{return {value: val, label: val}});
                inputCmp = (
                    options && <LazyMultipleSelector options={options.split('\n').map((item) => item.trim()).filter((i) => i)
                        .map((item)=> { return  {
                            label: item,
                            value: item,
                        }})} value={va} onSelected={(val)=> onChange({target:{value: val.map(({value})=>value)}})}/>
                )
                break;
            case 'radio':
                inputCmp = ( options &&
                    <RadioGroup options={options.split('\n').map((item) => item.trim()).filter((i) => i)}
                                onChange={onChange} checked={value} name={label}
                    />
                );
                break;
            case "fileupload":
                inputCmp = <SimpleFileUpload value={value} imgWidth={200} onChange={(data)=>onChange({target:{value: data}})} />
                break;
            case 'checkbox':
                inputCmp = <div className={`form-group col-${12/(size || 1)}`}>
                    <Checkbox label={<FormattedMessage id={label} defaultMessage={label} />} checked={value} value={label} onChange={(e) => onChange({ target: { value: e.target.checked } })} />
                </div>;
                break;
            case 'currency':
                inputCmp = <CurrencySelector onChange={onChange} value={value} />
                break;
            default:
                inputCmp = (<input placeholder={placeholder} type={type || inputTypeMapper[id] || 'text'} id={id} name={fieldNameMapper[id] || label}
                                   value={value} onChange={onChange} autoComplete={autofillFieldMapper[id] || 'on'}
                                   className={'form-control'}
                />);

        }
        return (<div className={`form-group col-${12/(size || 1)}`}>
            {label && <Label required={isRequired} title={<FormattedMessage id={label} defaultMessage={label} />}/>}
            {
                (prepend || append) ?
                    <div className="input-group">
                        {
                            prepend &&
                            <div className="input-group-prepend">
                                <span className="input-group-text">{prepend}</span>
                            </div>
                        }
                        {
                            inputCmp
                        }
                        {
                            append &&
                            <div className="input-group-append">
                                <span className="input-group-text">{append}</span>
                            </div>
                        }
                    </div> : inputCmp

            }
        </div>);
    }
}


const autofillFieldMapper = {
    name: 'name',
    fname: 'given-name',
    phone: 'tel',
    address: 'address',
    address_1: 'address-line1',
    address_2: 'address-line2',
    lname: 'family-name',
    email: 'email',
    password: 'new-password',
    state: 'address-level1',
    city: 'address-level2',
    zip: 'postal-code',
    country: 'country'
};


const inputTypeMapper = {
    email: 'email',
    phone: 'tel',
    paypal:'email',
    date:'date',
};
const fieldNameMapper = {
    name: 'name',
    fname: 'fname',
    lname: 'lname',
    phone: 'phone',
    email: 'email',
    address_1: 'address',
    address: 'address',
    city: 'city',
    state: 'state',
    country: 'country',
    zip: 'zip'
};

export default RenderFormInput;
