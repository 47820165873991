/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type CommissionRateType = "FixedAmountOnOrder" | "FlatRate" | "Percentage" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type StoreDetails_affiliate$ref: FragmentReference;
declare export opaque type StoreDetails_affiliate$fragmentType: StoreDetails_affiliate$ref;
export type StoreDetails_affiliate = {|
  +referralCodes: ?$ReadOnlyArray<string>,
  +commissionRate: {|
    +special: $ReadOnlyArray<{|
      +product: ?{|
        +id: string,
        +name: string,
      |},
      +variation: ?{|
        +id: string,
        +name: string,
      |},
      +commission: {|
        +commissionType: CommissionRateType,
        +commissionValue: number,
      |},
      +collection: ?{|
        +id: string,
        +name: string,
      |},
    |}>,
    +standard: {|
      +commissionType: CommissionRateType,
      +commissionValue: number,
    |},
  |},
  +couponCodes: ?$ReadOnlyArray<{|
    +code: string
  |}>,
  +$refType: StoreDetails_affiliate$ref,
|};
export type StoreDetails_affiliate$data = StoreDetails_affiliate;
export type StoreDetails_affiliate$key = {
  +$data?: StoreDetails_affiliate$data,
  +$fragmentRefs: StoreDetails_affiliate$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "commissionType",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "commissionValue",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StoreDetails_affiliate",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "referralCodes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SiteCommissionRate",
      "kind": "LinkedField",
      "name": "commissionRate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SpecialCommissionRates",
          "kind": "LinkedField",
          "name": "special",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "IdAndNamePair",
              "kind": "LinkedField",
              "name": "product",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "IdAndNamePair",
              "kind": "LinkedField",
              "name": "variation",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CommissionRate",
              "kind": "LinkedField",
              "name": "commission",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "IdAndNamePair",
              "kind": "LinkedField",
              "name": "collection",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CommissionRate",
          "kind": "LinkedField",
          "name": "standard",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CouponCode",
      "kind": "LinkedField",
      "name": "couponCodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MySite",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a94608db3440e267577d6e07b9ec1dd6';

module.exports = node;
