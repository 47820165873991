import React from 'react';
import {useIntl} from "react-intl";

function RenderAmount({value, currency}) {
    const intl = useIntl()
    return (
        intl.formatNumber(value, {style:'currency', currency})
    );
}

export default RenderAmount;
