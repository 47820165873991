import React, {useEffect, useState} from 'react';
import LazyRangePicker from "../../components/datepickers/LazyRangePicker";
import useQuery, {appendToQuery} from "../../components/hooks/useQuery";
import RenderDate from "../../components/RenderDate";
import dayjs from 'dayjs';
import { useNavigate, useLocation} from "react-router-dom";
import Label from "../../components/form/Label";
import StoreFilter from "../../components/StoreFilter";
import {useFragment, usePreloadedQuery, useQueryLoader} from "react-relay";
import graphql from 'babel-plugin-relay/macro'
import GraphQLLegacyPagination from "../../components/pagination/GraphQLLegacyPagination";
import Skeleton from "react-loading-skeleton";
import RenderOrderCommission from "../../components/app-components/RenderOrderCommission";
import {FormattedNumber} from 'react-intl';

const ql = graphql`
    query AgencyOrdersGraphQLQuery($limit: Int, $offset: Int, $siteId: ID, $startDate: DateTime, $endDate: DateTime){
        orders(first: $limit, skip: $offset, siteId: $siteId, startDate: $startDate, endDate: $endDate){
            edges{
                node{
                    ...AgencyOrdersGraphQL_orderItem
                }
            }
            ...GraphQLLegacyPagination_legacyPageInfo
        }
    }
`
function AgencyOrdersGraphQL(props) {
    const [queryRef, loadQuery, dispose] = useQueryLoader(ql)
    const [dates, setDates] = useState([dayjs().subtract(7, 'days').startOf('day').toDate(), new Date()])

    const query = useQuery();
    const limit = Number(query.limit || 10);
    const page = Number(query.page || 0);

    useEffect(()=>{
        const created_at_max = query.created_at_max ? new Date(Number(query.created_at_max)) : null
        const created_at_min = query.created_at_min ? new Date(Number(query.created_at_min)) : null
        setDates([created_at_min, created_at_max]);
    }, [query.created_at_max, query.created_at_min])

    useEffect(()=>{
        console.log('dates', dates)
        loadQuery({
                startDate: dates && dates.length > 0 && dates[0]  ? new Date(Number(dates[0])).toISOString()  : null,
                endDate: dates && dates.length > 1 && dates[1] ?  new Date(Number(dates[1])).toISOString() : null,
                limit: limit,
                offset: page * limit,
                siteId: query.site_id
        })
        return dispose;
    }, [limit, page, dates, query.site_id])

    const navigate = useNavigate();
    const location = useLocation()
    function onDateChange(dates){
        const startDate = (dates && dates[0]) || dayjs().subtract(7, 'days').startOf('day').toDate();
        const endDate = (dates && dates[1]) || new Date()
        navigate(appendToQuery(location, {created_at_max: endDate.getTime(), created_at_min: startDate.getTime()}))
    }
    if(!queryRef) return null;
    return (
        <>
         <h1>Orders</h1>
            <div className="row mb-2">
                <div className="col-md-6">
                    <Label title={"Filter by store"}/>
                    <StoreFilter value={query.site_id} onChange={({value})=>{
                        navigate(appendToQuery(location, {site_id: value}))
                    }}/>
                </div>
                <div className="col-md-6">
                    <Label title={"Filter by dates"}/>
                    <LazyRangePicker value={dates} onChange={onDateChange}/>
                </div>
            </div>
            <React.Suspense fallback={<Skeleton count={limit * 2.5}/>}>
                <RenderTable queryRef={queryRef}/>
            </React.Suspense>
        </>
    );
}

function RenderTable({queryRef}){
    const data = usePreloadedQuery(ql, queryRef)
    if(!data || !data.orders){
        return null;
    }
    return <>

        <ul className="list-group">
            {
                data.orders.edges.length === 0 && <p className="mt-4 text-center">
                    No orders
                </p>
            }
            {
                data.orders.edges.map(({node, cursor})=>{
                    return <OrdersLineItem node={node} key={cursor} />
                })
            }
        </ul>
        <GraphQLLegacyPagination fragmentData={data.orders}/>
        </>
}

function OrdersLineItem({node}){
    const data = useFragment(graphql`
        fragment AgencyOrdersGraphQL_orderItem on Order{
            createdAt
            amount
            currency
            commission
            number
            status
            site {
                website
                name
            }
        }
    `, node)
    const {createdAt, site, amount, currency, commission, number, status} = data
    const {name} = site;
    return (
        <li className={"list-group-item"}>
            <div className="row">
                <div className="col-md-3 col-6">
                    <div>
                        <RenderDate value={createdAt}/>
                    </div>
                    <small className={"text-muted"}>{name}</small>
                </div>

                <div className="col-md-3 col-6 text-md-center text-right text-lg">
                    <div>{number}</div>
                    <small className={"text-muted"}>Order #</small>
                </div>
                <div className="col-md-3 col-6  text-md-right ">
                    <div><FormattedNumber currency={currency} style={"currency"} value={amount}  /></div>
                    <small className={"text-muted"}>Amount</small>
                </div>
                <div className="col-md-3 col-6 text-right text-lg">
                    <RenderOrderCommission status={status} commission={commission} currency={currency}/>
                    <small className={"text-muted d-block"}>Commission</small>
                </div>
            </div>

        </li>
    )
}


export default AgencyOrdersGraphQL;
