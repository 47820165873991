import React, {useEffect} from 'react';
import CurrencyNumber from "../../components/CurrencyNumber";
import Skeleton from "react-loading-skeleton";
import graphql from 'babel-plugin-relay/macro';
import {useFragment, usePreloadedQuery, useQueryLoader} from "react-relay";

const query = graphql`
    query PayoutsPendingQuery{
        pendingPayouts {
            edges{
                node{
                    ...PayoutsPending_item
                }
            }
        }
    }
`

function PayoutsPending(){
    const [queryRef, loader, dispose] = useQueryLoader(query)
    useEffect(()=>{
        loader()
        return dispose
    },[])
    if(!queryRef){
        return null
    }
    return <React.Suspense fallback={<Skeleton count={15}/>}>
        <RenderData queryRef={queryRef}/>
    </React.Suspense>
}

function RenderData({queryRef}) {
    const data = usePreloadedQuery(query, queryRef)
    return (
        <>
            {
                data && data.pendingPayouts ? <>
                        {
                            data.pendingPayouts.edges.length > 0 ?
                                <ul className={"list-group mt-2"}>
                                    {
                                        data.pendingPayouts.edges.map(({node})=>{
                                            return <PendingItem fragmentData={node}/>
                                        })
                                    }
                                </ul> :
                                <p className={"text-center mt-4"}>No payments are pending</p>
                        }</>
                    : null
            }
        </>
    );
}

function PendingItem({fragmentData}){
    const data = useFragment(graphql`
        fragment PayoutsPending_item on PendingPayout{
            site{
                currency
                website
                name
            }
            netAmountPending
            totalAmountPaid
            totalOtherEarnings
            totalSaleEarnings
        }
    `, fragmentData)
    if(!data){
        return null
    }
    return <li className={"list-group-item d-flex justify-content-between"}>
        <span>
            {data.site.name}
            <small className={'d-block text-muted'}>
                {data.site.website}
            </small>
        </span>
        <span className={"text-success"}>
            <CurrencyNumber currency={data.site.currency} value={data.netAmountPending}/>
        </span>
    </li>
}

export default PayoutsPending;
