import React from 'react';

export default function MySubAffiliates(){
    return (
        <>
            <div className="flex items-center justify-content-between">
                <div>
                    <h1>Agency Affiliates</h1>
                    <p className="text-muted">Add affiliates to your marketing agency and earn commissions from their sales. </p>
                </div>

            </div>
        </>
    )
}
