/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type RecentPayoutsGraphQL_listItem$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RecentPayoutsGraphQL_payouts$ref: FragmentReference;
declare export opaque type RecentPayoutsGraphQL_payouts$fragmentType: RecentPayoutsGraphQL_payouts$ref;
export type RecentPayoutsGraphQL_payouts = {|
  +payouts: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +$fragmentRefs: RecentPayoutsGraphQL_listItem$ref
      |}
    |}>
  |},
  +$refType: RecentPayoutsGraphQL_payouts$ref,
|};
export type RecentPayoutsGraphQL_payouts$data = RecentPayoutsGraphQL_payouts;
export type RecentPayoutsGraphQL_payouts$key = {
  +$data?: RecentPayoutsGraphQL_payouts$data,
  +$fragmentRefs: RecentPayoutsGraphQL_payouts$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecentPayoutsGraphQL_payouts",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 5
        }
      ],
      "concreteType": "PayoutsConnection",
      "kind": "LinkedField",
      "name": "payouts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PayoutsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Payout",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RecentPayoutsGraphQL_listItem"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "payouts(first:5)"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '41614205a7f3d5ef565a753347fdd150';

module.exports = node;
