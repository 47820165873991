import React, {useCallback, useEffect, useState} from 'react';
import SettingsSection from '../../../components/layout/SettingsSection';
import Button from '../../../components/form/Button';
import {nanoid} from 'nanoid';
import useProfile from '../../../stores/useProfile';

function SocialProfile() {
    const [links, setLinks] = useState([])
    const [hasChanged, setHasChanged] = useState(false);
    const [profile, httpUpdateProfile] = useProfile(useCallback((state)=>[state.profile, state.httpUpdateProfile],[]))
    const [isUpdating, setIsUpdating] = useState(false)
    useEffect(()=>{
        if(profile){
            setLinks(profile.socials)
            setHasChanged(false)
        }
    }, [profile])

    function addLink(){
        setLinks([...links, {
            id: nanoid(),
            reach:0,
            url:''
        }])
    }

    function onSave(e){
        if(e){
            e.preventDefault()
            e.stopPropagation()
        }
        if(hasChanged) {
            if (!hasInvalidUrl()) {
                setIsUpdating(true)
                //detect platform
                httpUpdateProfile({
                    socials: links.filter((item) => item.url)
                }).then(()=>{
                    setIsUpdating(false)
                })
            }
        }else{
            addLink()
        }
    }

    const onInputChange = (id)=>(change)=>{
        setLinks(links.map((item)=>{
            return item.id === id ? {...item, ...change} :  item;
        }))
        setHasChanged(true)
    }

    const onLinkDeleted = (id)=>()=>{
        setLinks(links.filter((item)=>item.id !== id))
    }

    const hasInvalidUrl = ()=>{
        return links.find((item)=>{
            try {
                new URL(item.url)
            }catch(e){
                return true;
            }
        })
    }

    return (
        <SettingsSection title={"Social Profile"} subtitle={"Add the social media accounts or pages where you will advertise the merchant's products"}>
            <form onSubmit={onSave}>
            {
                links.map((item)=>{
                    return <SocialInput
                            key={String(item.id)}
                            onDeleted={onLinkDeleted(item.id)}
                            onChange={onInputChange(item.id)}
                            platform={item.platform}
                            reach={item.reach}
                            url={item.url} />
                })
            }
            <div className="text-center">
                    {
                        isUpdating || hasChanged ? <Button
                                disabled={hasInvalidUrl()}
                                className={"btn btn-primary"} onClick={onSave}
                                             type={"submit"}
                                             loading={isUpdating}
                                             title={"Submit"}/> :
                            <Button className={"btn-sm btn btn-outline-primary"}
                                    type={"button"}
                                    onClick={addLink}
                                    title={"Add social link"}/>
                    }
                </div>
            </form>
        </SettingsSection>
    );
}

function SocialInput({url, onChange, onDeleted}){
    const [isUrlInvalid, setIsUrlInvalid] = useState(false)
    const onInput = key=>e=>{
        onChange({
            url,
            [key]:e.target.value,
        })
    }
    useEffect(()=>{
        if(url) {
            try {
                new URL(url)
                setIsUrlInvalid(false)
            } catch (e) {
                setIsUrlInvalid(true)
            }
        }else{
            setIsUrlInvalid(false)
        }
    },[url])
    return <div className="row mb-2">
        <div className="col-11">
            <input type="text" value={url} onChange={onInput('url')} className="form-control" placeholder={"https://facebook.com/goaffpro"}/>
            {
                isUrlInvalid ? <p className={"text-danger"}>Invalid URL</p> : null
            }
        </div>
        <div className="col-1">
            <Button className={"btn btn-outline-danger"} type={"button"} onClick={onDeleted}>X</Button>
        </div>
    </div>
}

export default SocialProfile;
