import React, {useCallback, useEffect, useState} from 'react';
import FormGroup from '../../../components/form/FormGroup';
import InputGroup from '../../../components/form/InputGroup';
import Button from '../../../components/form/Button';
import {areObjectEqual} from '../../../utils/func';
import ProfilePhotoUploader from './ProfilePhotoUploader';
import useProfile from '../../../stores/useProfile';

function ProfileSection(){
    const [profile, httpUpdateProfile] = useProfile(useCallback((state)=>[state.profile, state.httpUpdateProfile],[]))
    useEffect(()=>{
        if(profile) {
            setFormData(profile)
        }
    },[profile])
    const [saving, setSaving] = useState(false)
   function onSubmit(e){
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }
          setSaving(true)
        const {firstName, lastName, referralCode} = formData
           httpUpdateProfile({
                    firstName,
                    lastName,
                    referralCode,
                }).then(()=>{
                    setSaving(false)
        })
    }

    const [formData, setFormData] = useState({
        firstName:'',
        lastName:'',
        companyName:'',
        website:'',
        referralCode:'',
    })

    const onChange = key => e=>{
        setFormData({...formData, [key]:e.target.value})
    }

    function hasChanged(){
        return !areObjectEqual(['firstName','lastName','referralCode'], profile, formData)
    }

    return (
        <>
            <form onSubmit={onSubmit}>
                <FormGroup label={"Your photo"}>
                    <ProfilePhotoUploader />
                </FormGroup>
                <FormGroup label={"Your name"}>
                    <div className="row">
                        <div className="col">
                            <InputGroup placeholder={"First name"} value={formData.firstName} onChange={onChange('firstName')} />
                        </div>
                        <div className="col">
                            <InputGroup placeholder={"Last name"} value={formData.lastName} onChange={onChange('lastName')} />
                        </div>
                    </div>
                </FormGroup>
                <FormGroup label={"Default referral code"}>
                    <input type="text" className="form-control" value={formData.referralCode} onChange={onChange('referralCode')}/>
                </FormGroup>
                {
                    hasChanged() &&
                    <Button onClick={onSubmit} className={"btn btn-primary"} title={"Submit"} loading={saving}/>
                }
            </form>
        </>
    )

}

export default ProfileSection
