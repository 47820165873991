import React, {Component} from 'react';
import SignatureCanvas from 'react-signature-canvas'
import PropTypes from 'prop-types';

class SignatureInput extends Component {
    state = {
        imgData:''
    }

    dispatchChange = ()=>{
        if(this.props.onChange) {
            this.props.onChange({target: {value: this.sigCanvas.toData()}})
        }
    }

    renderCanvas = (ref)=>{
        this.sigCanvas = ref
        if(this.sigCanvas) {
            if(this.props.value) {
                this.sigCanvas.fromData(this.props.value)
            }
            if(!this.props.onChange) this.setState({imgData: this.sigCanvas.toDataURL()});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.value !== this.props.value && this.sigCanvas){
            this.sigCanvas.fromData(this.props.value)
        }
    }

    resetCanvas = ()=>{
        this.sigCanvas.fromData([]);
        this.dispatchChange();
    }

    render() {
        console.log('got value', this.props.value);
        return (
            <div className="border" style={{width:300}}>
                {
                    !this.props.onChange && <div style={{width:300, height:200, position:'absolute', backgroundColor:'rgba(0,0,0,0)'}}>
                        <img src={this.state.imgData} style={{width:300, height:200 }} />
                    </div>
                }
                <SignatureCanvas onEnd={this.dispatchChange} ref={this.renderCanvas} penColor='blue'
                                 canvasProps={{width: '300', height: 200, className: 'sigCanvas'}}
                />

                {
                    this.props.onChange &&
                    <button type={"button"} className={"btn btn-link text-danger"}
                            onClick={this.resetCanvas}>Clear</button>
                }
            </div>
        );
    }
}
SignatureInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.array,
}

export default SignatureInput;
