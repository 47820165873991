import React from 'react';
import GoaffproLogo from "./GoaffproLogo";
import {LogoutIcon} from './Icons'
import {Link} from "react-router-dom";
function GoaffproTopBanner(props) {
    return (
        <header className={"bg-dark py-2 d-flex align-items-center justify-content-between"}>
            <GoaffproLogo inverted />
            <Link to={"/auth/logout"} className={"text-light font-weight-lighter px-2 py-2"}>
                <LogoutIcon style={{width:20, height:20}}/>
            </Link>
        </header>
    );
}

export default GoaffproTopBanner;
