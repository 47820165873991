import {Environment, Network, RecordSource, Store} from 'relay-runtime';

//const url = process.env.NODE_ENV === "development" ? "https://api-server-1.goaffpro.com/":"https://api.goaffpro.com/"
const url = "https://api-server-1.goaffpro.com/"
export async function fetchGraphQL(query, variables) {
    return fetch(url+'graphql', {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query,
            variables,
        }),
    }).then((response)=>response.json()).catch((e)=>{
        console.log(e)
        return {}
    });
}

async function fetchRelay(params, variables) {
    console.log(`fetching query ${params.name} with ${JSON.stringify(variables)}`);
    return fetchGraphQL(params.text, variables);
}



// Export a singleton instance of Relay Environment configured with our network function:
export default new Environment({
    network: Network.create(fetchRelay),
    store: new Store(new RecordSource()),
});
