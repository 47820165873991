import React, {useCallback, useState} from 'react';
import PageTitle from '../../components/layout/PageTitle';
import useInputState from '../../components/hooks/useInputState';
import Select from '../../components/form/Select';
import FormGroup from '../../components/form/FormGroup';
import LazyRangePicker from '../../components/datepickers/LazyRangePicker';
import StoreFilter from '../../components/StoreFilter';
import Button from '../../components/form/Button';
import dayjs from 'dayjs';
import {fetchGraphQL} from '../../RelayEnvironment';

const fetchReports = ({type, dates, siteId})=> fetchGraphQL(`
    mutation ReportsIndexMutation ($type: ReportType, $dates: DateRangeInput, $siteId: Int) {
        reports(reportType: $type, dateRange: $dates, siteReferenceId: $siteId){
            downloadUrl
        }
    }
`,{
    type, dates, siteId
}).then(({data})=>data)
function ReportsIndex(props) {
    const [reportType, setReportType] = useInputState('ORDERS')
    const [dates, setDates] = useState([
        dayjs().startOf('month').toDate(),
        new Date()
    ])
    const [downloadUrl, setDownloadUrl] = useState(null)
    const [store, setStore] = useState(null)
    const [loading, setLoading] = useState(false)
    const generateReport = useCallback(()=>{
        setLoading(true)
        fetchReports({
                dates:{
                    startDate: dates[0],
                    endDate: dates[1]
                },
                type: reportType,
                siteId: store ? Number(store) : undefined
            }).then(({reports})=>{
                setLoading(false)
                setDownloadUrl(reports.downloadUrl)
            })
    },[dates, reportType, store])
    return (
        <>
            <PageTitle title={"Reports"} subtitle={"Download reports in CSV format"}/>
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <div className="card">
                        <div className="card-body">
                            <FormGroup label={"Report type"}>
                                <Select value={reportType} onChange={setReportType} options={[
                                    {
                                        label:'Stores',
                                        value:'STORES'
                                    },
                                    {
                                        label:'Orders/Sales',
                                        value:'ORDERS'
                                    },{
                                        label:'Payout History',
                                        value: 'PAYOUTS'
                                    },{
                                        label:'Traffic',
                                        value: 'TRAFFIC'
                                    }
                                ]}/>
                            </FormGroup>
                            {
                                reportType !== "STORES" ? <>

                                    <FormGroup label={"Filter by Dates"}>
                                        <LazyRangePicker onChange={setDates}
                                                         value={dates}/>
                                    </FormGroup>
                                    <FormGroup label={"Filter by site"}>
                                        <StoreFilter
                                            onChange={({value}) => setStore(
                                                value)} value={store}/>
                                    </FormGroup> </> : null
                            }
                            {
                                downloadUrl &&  <p className={"alert alert-success"}>Report successfully generated. <a href={downloadUrl} className={"alert-link"} target={"_blank"} download>Download report</a>
                                </p>
                            }
                            <Button className={"btn btn-primary"} loading={loading} title={"Download"} onClick={generateReport}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportsIndex;
