/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type GraphQLLegacyPagination_legacyPageInfo$ref = any;
export type OrderStatus = "APPROVED" | "PENDING" | "REJECTED" | "%future added value";
export type TrafficPageQueryVariables = {|
  skip?: ?number,
  limit?: ?number,
  startDate?: ?any,
  endDate?: ?any,
  converted?: ?boolean,
|};
export type TrafficPageQueryResponse = {|
  +traffic: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +createdAt: any,
        +landingPage: ?any,
        +subID: ?string,
        +userAgent: ?string,
        +site: {|
          +website: string,
          +name: string,
        |},
        +order: ?{|
          +number: string,
          +amount: number,
          +commission: number,
          +currency: string,
          +status: OrderStatus,
        |},
        +ipAddress: ?any,
      |}
    |}>,
    +$fragmentRefs: GraphQLLegacyPagination_legacyPageInfo$ref,
  |}
|};
export type TrafficPageQuery = {|
  variables: TrafficPageQueryVariables,
  response: TrafficPageQueryResponse,
|};
*/


/*
query TrafficPageQuery(
  $skip: Int
  $limit: Int
  $startDate: Date
  $endDate: Date
  $converted: Boolean
) {
  traffic(skip: $skip, first: $limit, startDate: $startDate, endDate: $endDate, converted: $converted) {
    edges {
      node {
        createdAt
        landingPage
        subID
        userAgent
        site {
          website
          name
          id
        }
        order {
          number
          amount
          commission
          currency
          status
          id
        }
        ipAddress
      }
    }
    ...GraphQLLegacyPagination_legacyPageInfo
  }
}

fragment GraphQLLegacyPagination_legacyPageInfo on GraphQLPagination {
  __isGraphQLPagination: __typename
  legacyPageInfo {
    count
    offset
    limit
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "converted"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v5 = [
  {
    "kind": "Variable",
    "name": "converted",
    "variableName": "converted"
  },
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "endDate"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "skip",
    "variableName": "skip"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "startDate"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "landingPage",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subID",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userAgent",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "website",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "commission",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ipAddress",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TrafficPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "TrafficConnection",
        "kind": "LinkedField",
        "name": "traffic",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TrafficEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Traffic",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Site",
                    "kind": "LinkedField",
                    "name": "site",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Order",
                    "kind": "LinkedField",
                    "name": "order",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v17/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GraphQLLegacyPagination_legacyPageInfo"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TrafficPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "TrafficConnection",
        "kind": "LinkedField",
        "name": "traffic",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TrafficEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Traffic",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Site",
                    "kind": "LinkedField",
                    "name": "site",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v18/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Order",
                    "kind": "LinkedField",
                    "name": "order",
                    "plural": false,
                    "selections": [
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v18/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v17/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LegacyPageInfo",
                "kind": "LinkedField",
                "name": "legacyPageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "count",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "offset",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "limit",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "GraphQLPagination",
            "abstractKey": "__isGraphQLPagination"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9cb4912858a325d87fdc873a311c6aab",
    "id": null,
    "metadata": {},
    "name": "TrafficPageQuery",
    "operationKind": "query",
    "text": "query TrafficPageQuery(\n  $skip: Int\n  $limit: Int\n  $startDate: Date\n  $endDate: Date\n  $converted: Boolean\n) {\n  traffic(skip: $skip, first: $limit, startDate: $startDate, endDate: $endDate, converted: $converted) {\n    edges {\n      node {\n        createdAt\n        landingPage\n        subID\n        userAgent\n        site {\n          website\n          name\n          id\n        }\n        order {\n          number\n          amount\n          commission\n          currency\n          status\n          id\n        }\n        ipAddress\n      }\n    }\n    ...GraphQLLegacyPagination_legacyPageInfo\n  }\n}\n\nfragment GraphQLLegacyPagination_legacyPageInfo on GraphQLPagination {\n  __isGraphQLPagination: __typename\n  legacyPageInfo {\n    count\n    offset\n    limit\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd61a1de93aee53f111977aa401570fdf';

module.exports = node;
