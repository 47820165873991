import React from 'react';
import RenderAmount from "./RenderAmount";

function RenderOrderCommission({commission, currency, status}){
    if(status === "REJECTED") {
        return <span className="text-danger"><strike>
                <RenderAmount value={commission} currency={currency}/>
                </strike>
            </span>
    }else if(status === "PENDING"){
        return <span className="text-warning">
                <RenderAmount value={commission} currency={currency}/>
            </span>
    }else {
        return <span className="text-success">
                <RenderAmount value={commission} currency={currency}/>
            </span>
    }
}

export default RenderOrderCommission;
