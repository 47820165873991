import axios from 'axios';
import * as localStorage from './storage';

const uniq = require('lodash.uniq')
// export const baseURL = 'http://localhost:8080';
const baseURL = process.env.NODE_ENV === "development"?
    'https://api-server-1.goaffpro.com':
    'https://api.goaffpro.com';

export const appURL = process.env.NODE_ENV === "development"? 'http://localhost:3000':'https://app.goaffpro.com';
export function getHttpClient(config={}){
    const token = localStorage.getItem("access_token");
    const headers = token ? {'Authorization':`Bearer ${token}`} : {};
    return axios.create({
        baseURL,
        // timeout: 30000,
        headers,
        withCredentials: true,
        ...config
    });
}

export function login({email, password, site_domain}){
    return getHttpClient().post('/login',{
        email, password,  site_domain
    })
}

export function loginSiteOwner({email, password}){
    return getHttpClient().post('/users/login', {
        email, password
    }).then(({data})=>{
        if(data.access_token){
            localStorage.setItem("access_token", data.access_token)
        }
        return data;
    })
}
export function fetchSites(siteIds){
    return getHttpClient().get('/v1/user/sites', {
        params:{
            site_id: uniq(siteIds).join(","),
            fields:'id, name, currency, website'
        }
    }).then(({data})=>{
        return data.sites
    })
}


export const swrFetcher = url => getHttpClient().get(url).then(res => res.data)
