/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BrowseStores_item$ref = any;
type GraphQLLegacyPagination_legacyPageInfo$ref = any;
export type BrowseStoresQueryVariables = {|
  currencies?: ?$ReadOnlyArray<any>,
  search?: ?string,
  limit?: ?number,
  skip?: ?number,
|};
export type BrowseStoresQueryResponse = {|
  +sites: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +id: string,
        +$fragmentRefs: BrowseStores_item$ref,
      |}
    |}>,
    +$fragmentRefs: GraphQLLegacyPagination_legacyPageInfo$ref,
  |}
|};
export type BrowseStoresQuery = {|
  variables: BrowseStoresQueryVariables,
  response: BrowseStoresQueryResponse,
|};
*/


/*
query BrowseStoresQuery(
  $currencies: [Currency!]
  $search: String
  $limit: Int
  $skip: Int
) {
  sites(skip: $skip, currencies: $currencies, search: $search, first: $limit) {
    ...GraphQLLegacyPagination_legacyPageInfo
    edges {
      node {
        id
        ...BrowseStores_item
      }
    }
  }
}

fragment BrowseStores_item on Site {
  referenceId
  name
  logo
  currency
  affiliatePortal
  website
  cookieDuration
  areRegistrationsOpen
  isApprovedAutomatically
  commissionRate {
    standard {
      commissionType
      commissionValue
    }
  }
}

fragment GraphQLLegacyPagination_legacyPageInfo on GraphQLPagination {
  __isGraphQLPagination: __typename
  legacyPageInfo {
    count
    offset
    limit
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "currencies"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v4 = [
  {
    "kind": "Variable",
    "name": "currencies",
    "variableName": "currencies"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "skip",
    "variableName": "skip"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BrowseStoresQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "SitesConnection",
        "kind": "LinkedField",
        "name": "sites",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SiteEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "BrowseStores_item"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GraphQLLegacyPagination_legacyPageInfo"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "BrowseStoresQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "SitesConnection",
        "kind": "LinkedField",
        "name": "sites",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SiteEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "referenceId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "logo",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "affiliatePortal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "website",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cookieDuration",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "areRegistrationsOpen",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isApprovedAutomatically",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SiteCommissionRate",
                    "kind": "LinkedField",
                    "name": "commissionRate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CommissionRate",
                        "kind": "LinkedField",
                        "name": "standard",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "commissionType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "commissionValue",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LegacyPageInfo",
                "kind": "LinkedField",
                "name": "legacyPageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "count",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "offset",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "limit",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "GraphQLPagination",
            "abstractKey": "__isGraphQLPagination"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "93d1a5673ebf805d077468d3174f709c",
    "id": null,
    "metadata": {},
    "name": "BrowseStoresQuery",
    "operationKind": "query",
    "text": "query BrowseStoresQuery(\n  $currencies: [Currency!]\n  $search: String\n  $limit: Int\n  $skip: Int\n) {\n  sites(skip: $skip, currencies: $currencies, search: $search, first: $limit) {\n    ...GraphQLLegacyPagination_legacyPageInfo\n    edges {\n      node {\n        id\n        ...BrowseStores_item\n      }\n    }\n  }\n}\n\nfragment BrowseStores_item on Site {\n  referenceId\n  name\n  logo\n  currency\n  affiliatePortal\n  website\n  cookieDuration\n  areRegistrationsOpen\n  isApprovedAutomatically\n  commissionRate {\n    standard {\n      commissionType\n      commissionValue\n    }\n  }\n}\n\nfragment GraphQLLegacyPagination_legacyPageInfo on GraphQLPagination {\n  __isGraphQLPagination: __typename\n  legacyPageInfo {\n    count\n    offset\n    limit\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '665d5eb5b7d5d426ad94e8bfc45315d7';

module.exports = node;
