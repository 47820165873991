import Loadable from "react-loadable";
import LazyLoadingBar from "../progressbars/ProgressLoader";
import PropTypes from "prop-types";

const LazyRangePicker =  Loadable({
    loader: () => import('./RangePicker'),
    loading: LazyLoadingBar,
});

LazyRangePicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    config: PropTypes.any,
    value: PropTypes.array.isRequired
};


export default LazyRangePicker
