import React, {useState} from 'react';
import CopyToClipboard from "react-copy-to-clipboard";
import PropTypes from "prop-types";

function CopyButton({text, className, copyBtnText}) {
    const [copied, setCopied] = useState(false)
    function onCopy(){
        setCopied(true)
        setTimeout(()=>{
            setCopied(false)
        }, 1000);
    }
    return (
        <CopyToClipboard text={text} onCopy={onCopy}>
            <button type={"button"} disabled={copied} className={className}>{copied ? 'Copied' : copyBtnText || 'Copy'}</button>
        </CopyToClipboard>    );
}

CopyButton.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
    copyBtnText: PropTypes.string
}
export default CopyButton;
