import React from "react";
import {Link} from "react-router-dom";

export default function SettingsSection({title, subtitle, children, customSubtitle, noBorder, requiresUpgrade}){
    return <div className={`row pb-2 ${!noBorder && 'border-bottom mb-4 pb-4'}`}>
        <div className={"col-md-6"}>
            <div className={"mb-2"}>
                <h5>{title}</h5>
                {
                    customSubtitle ?  subtitle: <div className={"text-muted"}>{subtitle}</div>
                }
                {
                    requiresUpgrade && <Link to={"/subscriptions"} className={"text-danger"}>Requires upgrade</Link>
                }
            </div>
        </div>
        <div className={"col-md-6"}>
            {children}
        </div>
    </div>
}
