import React from 'react';
import FormattedNumber from "./FormattedNumber";
import PropTypes from 'prop-types';

function CurrencyNumber({currency, value}){
    return (
        <FormattedNumber style={"currency"} currency={currency} value={Number(value)}/>
    );
}

CurrencyNumber.propTypes = {
    value: PropTypes.number,
    currency: PropTypes.string
};

export default CurrencyNumber;
