import FormattedNumber from "../FormattedNumber";
import CurrencyNumber from "../CurrencyNumber";
import React from "react";

function RenderCommission({commission_type, commission_value}){
    switch (commission_type) {
        case "Percentage":
        case "percentage":
            return <span><FormattedNumber value={commission_value} />%</span>;
        case "flat_rate":
        case "FlatRate":
            return <CurrencyNumber value={commission_value} />;
        case "fixed_amount_on_order":
        case "FixedAmountOnOrder":
            return <span><CurrencyNumber value={commission_value} /> per order</span>
        default:
            return null;
    }
}

export default RenderCommission
