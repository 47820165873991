import React, {useEffect} from 'react';
import RenderAmount from "../../components/app-components/RenderAmount";
import RenderDate from "../../components/RenderDate";
import useQuery, {appendToQuery} from "../../components/hooks/useQuery";
import {useNavigate, useLocation} from "react-router-dom";
import StoreFilter from "../../components/StoreFilter";
import {EmptyBoxIcon} from "../../components/Icons";
import Label from "../../components/form/Label";
import RenderUserPayment from "../../components/RenderUserPayment";
import URLBasedPagination from "../../components/pagination/URLBasedPagination";
import Skeleton from "react-loading-skeleton";
import graphql from 'babel-plugin-relay/macro';
import {useFragment, usePreloadedQuery, useQueryLoader} from "react-relay";

const q = graphql`
    query PayoutsHistoryQuery($limit: Int!, $offset: Int!, $siteId: Int) {
        payouts (first: $limit, skip: $offset, siteReferenceId: $siteId){
            edges{
                node{
                  ...PayoutsHistory_ListItem
                }
            }
            ...PayoutsHistory_pageInfo
        }
}`



function PayoutsHistory(props) {
    const [queryRef, loadQuery, dispose] = useQueryLoader(q)
    const navigate = useNavigate();
    const location = useLocation()
    const query = useQuery();
    const limit = Number(query.limit || 10);
    const page = Number(query.page || 0);
    const site_id = query.site_id;
    useEffect(()=>{
        loadQuery({
            limit,
            offset: page * limit,
            siteId: query.site_id
        })
        return dispose
    }, [query.page, query.limit, query.site_id, loadQuery, limit, page, dispose])

    if(!queryRef) return null;

    return (
        <>
            <div className="mb-2 px-3 py-2 bg-light">
                <Label title={"Filter by store"}/>
                <StoreFilter value={site_id} onChange={({value})=>{
                    navigate(appendToQuery(location, {site_id: value}))
                }}/>
                {
                    site_id &&
                    <div className="text-right">
                        <button className="btn btn-sm btn-link px-0" onClick={() => {
                            navigate(appendToQuery(location, {site_id: undefined}))
                        }}>Clear all filters
                        </button>
                    </div>
                }
            </div>
            <React.Suspense fallback={<Skeleton count={limit *3}/>}>
                <DisplayData queryRef={queryRef} page={page} limit={limit}/>
            </React.Suspense>
        </>
    );
}

function DisplayData({queryRef}){
    const data = usePreloadedQuery(q, queryRef)
    if(!data) return  null;
    return <>
        <NoResultsBox pageInfo={data.legacyPageInfo}/>
        {
            data.payouts.edges.length === 0 &&
            <p className={"text-center mt-4"}>No payments received</p>
        }
        <ul className="list-group">
            {
                data.payouts.edges.map(({node})=><ListItem payout={node} key={node.id}/>
            )}
        </ul>
        <PaginationSegment pageInfo={data.payouts}/>

    </>
}

const legacyPageInfoFragment = graphql`
    fragment PayoutsHistory_pageInfo on GraphQLPagination {
        legacyPageInfo{
            count
            offset
            limit
        }
    }
`

function PaginationSegment(props){
    const data = useFragment(legacyPageInfoFragment, props.pageInfo);
    if(!data) return null
    const {offset, count, limit} = data.legacyPageInfo;
    return  count > 0 ? <div className="mt-2">
        <URLBasedPagination page={Math.ceil(offset/limit)} count={count} limit={limit}/>
    </div> : null
}

function NoResultsBox(props){
    const data = useFragment(legacyPageInfoFragment, props.pageInfo);
    if(!data) return null
    const {count} = data.legacyPageInfo;
    return count === 0 ? <div className={"text-center rounded border p-4"}>
            <EmptyBoxIcon className={"text-muted display-4"}/>
            <div>No results</div>
        </div> : null
}

function ListItem(props){
    const data = useFragment(graphql`
        fragment PayoutsHistory_ListItem on Payout{
            id
            site{
                name
                website
            }
            createdAt
            amount
            currency
            note
            paymentMethod
            paymentDetails
        }
    `, props.payout)
    if(!data) return null
    return (
        <li className={"list-group-item"}>
            <div className="row">
                <div className="col-md-4">
                    <div>
                        <RenderDate value={new Date(data.createdAt)}/>
                    </div>
                    <small className={"text-muted"}>{data.site.website}</small>
                </div>

                <div className="col-md-4 col-6">
                    <div>
                        <RenderUserPayment payment_method={data.paymentMethod} payment_details_data={data.paymentDetails}/>
                    </div>
                </div>
                <div className="col-md-4 text-right text-lg">
                    <RenderAmount value={data.amount} currency={data.currency}/>
                </div>
            </div>

        </li>
    )
}


export default PayoutsHistory;
