export function getCurrentQueryObject(){
    const map = {};
    if(window.location.search) {
        window.location.search.substr(1).split("&").map((item) => {
            return item.split("=");
        }).forEach((x) => {
            if (x[1]) {
                map[x[0]] = decodeURIComponent(x[1].trim());
            }
        })
    }
    return map;
}


export function buildUrl(base, queryObject){
    const keys = Object.keys(queryObject);
    const str = keys.map((key)=>{
        return `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`
    });
    return `${base}?${str.join("&")}`;
}

export function shopifyRedirect(url){
    if(window.self === window.top) {
        window.location = url;
    }else{
        window.top.location.href = url
    }
}


export function isValidEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function monthAgo(){
    var d = new Date();
    d.setMonth(d.getMonth() - 1);
    return d;
}


export function getReviewLink(integration = "shopify"){
    switch (integration) {
        case "shopify":
            return "https://apps.shopify.com/goaffpro#modal-show=ReviewListingModal&utm_campaign=installed&utm_content=contextual&utm_medium=shopify&utm_source=admin"
        case "wordpress":
            return 'https://wordpress.org/plugins/goaffpro'
        //case "bigcommerce":
        //   return "https://"+window.top.location.host+"/manage/marketplace/apps/16547/reviews/edit"
        default:
            return null;
    }
}
const Big = require('big.js');
export function amountAfterWitholdingTax(amount, withholding_tax){
    return Big(withholding_tax || 0).div(100).minus(1).times(amount || 0).times(-1).toFixed(2)
}

/**
 * @param string
 * @param replacerObject
 */
export function replaceStrings (string, replacerObject) {
    // cleanup object
    if (replacerObject) {
        Object.keys(replacerObject).forEach((item) => {
            if (replacerObject[item] === undefined) {
                replacerObject[item] = ''
            }
        })
    }

    if (replacerObject && string) {
        let str = string
        Object.keys(replacerObject).forEach((item) => str = str.replace(new RegExp(`{${item}}`, 'g'), replacerObject[item]))
        return str
    }
    return string
}


export function reorderArray(list, startIndex, endIndex){
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
}

export function pad(num, paddingLength, paddingWith = "0"){
    return (Array.from({length:paddingLength}).map((item)=>paddingWith).join("")+num).substr(-paddingLength)
}

export function removeUndefined(object){
    Object.keys(object).forEach((item)=>{
        if(object[item] === undefined){
            delete object[item];
        }
    })
    return object;
}

const getExt = (filename = '')=>{
    const parts = filename.split(".")
    return parts && parts.length > 0 ? parts[parts.length-1].toLowerCase() : undefined;
}
const imageExtensions = ['gif','jpeg','jpg','png', 'bmp'];
const videoExtensions = ['mp4','mov','avi','3gp','mkv'];
export const isImage = (url)=>{
    console.log('checking is image', url)
    const ext = getExt(url);
    return ext && (imageExtensions.indexOf(ext) > -1)
}

/**
 * Returns true if both object are equal
 * @param keys
 * @param obj1
 * @param obj2
 * @returns {boolean}
 */
export function areObjectEqual(keys, obj1, obj2){
    for(let key of keys){
        if(obj1[key] !== obj2[key]){
            return false
        }
    }
    return true;
}
