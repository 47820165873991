import React, {useCallback, useEffect} from 'react';
import SettingsSection from '../../components/layout/SettingsSection';
import ProfileSection from './sections/ProfileSection';
import AddressSection from './sections/AddressSection';
import SocialProfile from './sections/SocialProfile';
import ChangeEmailAddress from './ChangeEmailAddress';
import ChangePassword from './ChangePassword';
import useProfile from '../../stores/useProfile';
import Checkbox from '../../components/form/Checkbox';

function SettingsIndex(){
    return <React.Suspense fallback={null}>
        <Settings/>
    </React.Suspense>
}

function IsAccountPublicCheckbox(){
    const [httpUpdateProfile, isAccountPublic] = useProfile(useCallback((state)=>[state.httpUpdateProfile, state.profile.isAccountPublic],[]))
    const toggle = useCallback((e)=>{
        return httpUpdateProfile({
            isAccountPublic: e.target.checked
        })
    },[httpUpdateProfile])
    return <div className={"text-right"}>
        <Checkbox label={"Make profile public"} onChange={toggle} checked={isAccountPublic}>Make profile public</Checkbox>
        <small className={"text-muted"}>Allow merchants to contact your directly</small>
    </div>
}

function Settings(props) {
    const [httpGetProfile, profile] = useProfile(useCallback((state)=>[state.httpGetProfile, state.profile],[]))
    useEffect(()=>{
        if(!profile){
            httpGetProfile()
        }
    },[httpGetProfile, profile])

    if(!profile) return null;

    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <div>
                    <h1>Settings</h1>
                    <p>Account ID: {profile.referenceId}</p>
                </div>
                <div>
                    <IsAccountPublicCheckbox />
                </div>
            </div>
            <hr/>
            <div className={'p-2'}>
                <SettingsSection title={"Profile"} subtitle={"Your profile"}>
                    <ProfileSection />
                </SettingsSection>
                <AddressSection />
                <SocialProfile />
                <SettingsSection title={"System"} subtitle={"Change system settings"}>
                    <div className="mb-2">
                        <ChangeEmailAddress />
                    </div>
                    <div className="mb-2">
                        <ChangePassword/>
                    </div>
                </SettingsSection>
                {/*
                <SettingsSection title={"Access token"} noBorder subtitle={<>Generate access token for use in our API<br/>
                    <a href={"https://api.goaffpro.com/docs/admin/#/user/"}>Documentation</a>
                </>}>
                <AccessTokens fragmentData={data.me} />
                </SettingsSection>

                <div className="row">
                    <div className="col-md-6">
                        <div>Usage</div>
                        <hr/>
                        <small><strong>BASE URL</strong></small>
                        <p className={"text-monospace"}>https://api.goaffpro.com/v1/user/</p>
                        <small><strong>Authorization Headers</strong></small>
                        <table className={"table table-sm table-bordered"}>
                            <thead>
                            <tr>
                                <th>Header</th>
                                <th>Value</th>
                            </tr>

                            </thead>
                            <tbody>
                            <tr>
                                <td>x-goaffpro-access-token</td>
                                <td>the-generated-access-token</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                */}
            </div>
        </>
    );
}
export default SettingsIndex
