import React, {useEffect, useState} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import StartContainer from '../../components/layout/StartContainer';
import LoginWithToken from './LoginWithToken';
import BackButton from '../../components/BackButton';

export default function AuthIndex(){

    return (
        <Routes>
            <Route path={"loginWithToken/:accessToken"} element={<LoginWithToken/>}/>
            <Route path={"logout"} element={<LogoutComponent/>}/>
            <Route index element={<RedirectTo/>}/>
        </Routes>
    );
}

function RedirectTo(){
    return <div className="bg-light min-vh-100">
        <div className="container pt-4">
            <div className="card">
                <div className="card-body text-center">
                    <h1>Not authorized</h1>
                    <p>You need to <a href={"https://goaffpro.com/login"}>login</a> to continue</p>
                </div>
            </div>
        </div>
    </div>
}

function LogoutComponent(){
    const navigate = useNavigate()
    const [isLogoutConfirmed, confirmLogout] = useState()
    useEffect(()=>{
        if(isLogoutConfirmed){
            localStorage.removeItem('access_token')
        }
    },[isLogoutConfirmed])
    function onComplete(){
        window.location.assign("https://goaffpro.com/login")
    }

    return <StartContainer>
        <div className="card">
            <div className="card-body">
        {
            isLogoutConfirmed ? <>
        <div className="text-center">
            <p>You have been successfully logged out</p>
            <p>Taking you to <a href={"/auth/login"}>login page</a> in <strong><Countdown onComplete={onComplete} startSeconds={5}/></strong></p>
        </div>
        </> : <>
                <div className="text-center">
                    <BackButton/>
                    <hr/>
                    <p>Do you wish to log out of your account ?</p>
                   <button className={"btn btn-danger mr-4"} onClick={()=>confirmLogout(true)}>Logout</button>
                    <button onClick={()=>navigate(-1)} className={"btn btn-outline-secondary ml-4"}>Go Back</button>
                </div>
            </>
        }

            </div>
        </div>

    </StartContainer>

}

function Countdown({startSeconds, onComplete}){
    const [seconds, setSeconds] = useState(startSeconds)
    useEffect(()=>{
        setSeconds(startSeconds)
    },[startSeconds])
    useEffect(()=>{
        setTimeout(()=>{
            if(seconds === 1){
                onComplete()
            }else {
                setSeconds(seconds - 1)
            }
        },1000)
    },[seconds])
    return seconds
}
