import React, {useEffect, useState} from 'react';
import {getHttpClient} from "../utils/api";

function StoreFilter({value, onChange}) {
    const [data, setData] = useState([])
    useEffect(()=>{
        getHttpClient().get('/v1/user/sites', {
            params:{
                fields:'id,name'
            }
        })
            .then(({data})=>{
                setData(data.sites.map((item)=>{
                    return {
                        value:item.id,
                        label: item.name,
                    }
                }))
            })
    }, [])

    /*
    useEffect(()=>{
        if(data || value){
            setVal(data.find((item)=>item.value == value))
        }else{
            setVal(null)
        }
    }, [value, data])

     */
    return (
        <select value={value || ""} className={"form-control"} onChange={(e)=>onChange(e.target)}>
            <option value={""}>All stores</option>
            {
                data.map((item)=>{
                    return <option key={String(item.value)} value={item.value}>{item.label}</option>
                })
            }
        </select>
    );
}

export default StoreFilter;
