import React from 'react';

function PageTitle({title, subtitle, children}) {
    return (
        <>
            <div className="row">
                <div className="col-md-8">
                    <h1>{title}</h1>
                    {subtitle &&  <p className="text-muted">{subtitle}</p>}
                </div>
                <div className="col-md-4">
                    {children}
                </div>
            </div>
        </>
    );
}

export default PageTitle;
