import {Link, useLocation, useNavigate} from 'react-router-dom';
import {appendToQuery} from '../hooks/useQuery';
import React from 'react';

function URLBasedPagination({page, count, limit}){
    const location = useLocation();
    const navigate = useNavigate();
    const next = appendToQuery(location,{page:page + 1})
    const previous = appendToQuery(location,{page:page - 1})
    function onLimitChange(e){
        navigate(appendToQuery(location,{page:0,limit:e.target.value}))
    }
    const maxPages = Math.ceil(count/limit);
    return (
        <div className="d-flex justify-content-between align-items-end">
            <select className="form-control" value={limit} onChange={onLimitChange} style={{maxWidth:150}}>
                <option value="3">3 per page</option>
                <option value="10">10 per page</option>
                <option value="25">25 per page</option>
                <option value="50">50 per page</option>
                <option value="100">100 per page</option>
            </select>
            <span>{`Page ${page+1} of ${maxPages}`}</span>
            <nav aria-label="Page navigation" className={'d-flex align-items-center'}>
                <ul className="pagination mb-0">
                    {
                        page > 0 && previous ?
                            <li className={`page-item ${page <= 0 ?
                                'disabled' :
                                ''}`}>
                                <Link to={page <= 0 ? undefined : previous}
                                      className="page-link">Previous</Link>
                            </li> : null
                    }
                    {
                        next ?
                            <li className={`page-item ${page >= maxPages - 1 ?
                                'disabled' :
                                ''}`}>
                                <Link to={next}
                                      className="page-link">Next</Link>
                            </li> : null
                    }
                </ul>
            </nav>
        </div>
    )
}
export default URLBasedPagination
