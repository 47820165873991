import React, {useCallback} from 'react';
import {BackButtonIcon} from "./Icons";
import {useNavigate} from 'react-router-dom';

export default function({to}){
    const navigate = useNavigate()
    const onClick = useCallback(()=>{
        return to ? navigate (to) : navigate(-1);
    },[navigate, to])

    return <button className={"btn d-flex px-0 text-sm text-dark row no-gutters align-items-center"} onClick={onClick}>
        <div className={"col-auto"}>
            <BackButtonIcon />
        </div>
        <div className={"col-auto"}>
            <span className={"ml-2"}> Go Back</span>
        </div>
    </button>
}
