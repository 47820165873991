import React from 'react';
import {Link} from "react-router-dom";

export default function GoaffproLogo({inverted}){
    return <Link to="/" id={"logo"}>
            <span className={"btn d-flex align-items-center"}>
                <img alt={"logo"} className="rounded" style={{width:32, height:32}} src={"/images/icon-square.svg"} />
                <div style={{fontSize:'1.2rem'}} className={`font-weight-bold pl-2 ${inverted ? 'text-white' : ''}`}>GOAFFPRO</div>
            </span>
        </Link>
}
