/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type RecentOrdersGraphQL_listItem$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RecentOrdersGraphQL_orders$ref: FragmentReference;
declare export opaque type RecentOrdersGraphQL_orders$fragmentType: RecentOrdersGraphQL_orders$ref;
export type RecentOrdersGraphQL_orders = {|
  +orders: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +$fragmentRefs: RecentOrdersGraphQL_listItem$ref
      |}
    |}>
  |},
  +$refType: RecentOrdersGraphQL_orders$ref,
|};
export type RecentOrdersGraphQL_orders$data = RecentOrdersGraphQL_orders;
export type RecentOrdersGraphQL_orders$key = {
  +$data?: RecentOrdersGraphQL_orders$data,
  +$fragmentRefs: RecentOrdersGraphQL_orders$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecentOrdersGraphQL_orders",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 5
        }
      ],
      "concreteType": "OrdersConnection",
      "kind": "LinkedField",
      "name": "orders",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrdersEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Order",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RecentOrdersGraphQL_listItem"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "orders(first:5)"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '69cbc54be931aa89898a820dcd10ef34';

module.exports = node;
