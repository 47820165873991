import React, {useEffect, useMemo, useState} from "react";
import Modal from "../../../components/Modal";
import Skeleton from "react-loading-skeleton";
import FormRenderer from "../../../components/form/FormRenderer";
import graphql from "babel-plugin-relay/macro";
import {useMutation, usePreloadedQuery, useQueryLoader} from "react-relay";

const me = graphql`
    query EnrollModalQuery($siteId: Int!) {
        me {
           firstName
           lastName
           email
           address{
               state
               addressLine1
               addressLine2
               city
               state
               country
               postalCode
           }
            socials {
                url
            }
           website
           companyName
        }
        siteById(referenceId: $siteId){
            signupForm (requiredFieldsOnly: true){
                type
                append
                prepend
                id
                placeholder
                isRequired
                options {
                    label
                    value
                }
                info
                label
            }
            name
            website
        }
    }
`

const enrollMutation = graphql`
    mutation EnrollModalMutation($siteId: Int!, $signupData: JSON!){
        enroll(siteReferenceId: $siteId, signupData: $signupData){
            ...on MySite{
                ...MyStores_site
            }   
            ...on Error{
                code
                message
            }
        }
    }
`

function EnrollModal({visible, onClose, storeId}){
    const [commit, isInFlight] = useMutation(enrollMutation);
    const [queryRef, loader, dispose] = useQueryLoader(me);

    useEffect(()=>{
        if(storeId) {
            loader({
                siteId: storeId
            })
        }
        return dispose
    },[storeId])

    const [data, setData] = useState({})
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(null)
    const [result, setResult] = useState(null);
    function enroll(e){
        setSaving(true)
        setError(false)
        if(e){
            e.preventDefault()
            e.stopPropagation()
        }
        commit({
            variables:{
                siteId: storeId,
                signupData: data
            },
            onCompleted({enroll}){
                setSaving(false)
                if(enroll.message){
                    setError(enroll.message)
                }else{
                    setResult("Enrollment successful. Please check My stores tab for more information")
                }
            },
            updater:(store)=>{
                /*
                const mySites = store.getRootField('mySites');
                if(mySites != null){
                    mySites.invalidateRecord();
                }
                 */
                store.invalidateStore()
            },
            onError(){
                setError('Network error');
                setSaving(false)
            }
        })
    }
    if(!queryRef) return null

    function onChange(data){
        setData(data)
    }

    function closeModal(){
        onClose()
        setResult(null)
        setData({})
        setError(null);
        setSaving(false)
    }
    return (
        <>
            <Modal okText={!result ? "Submit":"OK"}
                   confirmLoading={saving}
                   onSubmit={!result ? enroll : null}
                   title={"Complete signup"} visible={visible} onClose={closeModal}>

                {
                    result ? <p className={"text-success"}>{result}</p> :
                        <React.Suspense fallback={<Skeleton count={5}/>}>
                            <RenderForm queryRef={queryRef} values={data} onChange={onChange}/>
                        </React.Suspense>
                }
                {
                    error && <p className="text-danger text-center">{error}</p>
                }
            </Modal>
        </>
    )
}
function RenderForm({queryRef, onChange, values}) {
    const data = usePreloadedQuery(me, queryRef)
    const alreadyAvailableFields = useMemo(()=>{
        if(!data) return [];
        const {me} = data;
        if(!me) return [];
        /*
        const fb = me.socials.find(({url})=>['facebook','fb'].find((key)=>new URL(url).hostname.includes(key)))
        const twitter = me.socials.find(({url})=>['twitter'].find((key)=>new URL(url).hostname.includes(key)))
        const pinterest = me.socials.find(({url})=>['twitter'].find((key)=>new URL(url).hostname.includes(key)))
        const tiktok = me.socials.find(({url})=>['twitter'].find((key)=>new URL(url).hostname.includes(key)))
        const youtube = me.socials.find(({url})=>['youtube'].find((key)=>new URL(url).hostname.includes(key)))
        const instagram = me.socials.find(({url})=>['instagram'].find((key)=>new URL(url).hostname.includes(key)))
           */
        const map = {
            name:me.firstName,
            fname: me.firstName,
            lname: me.lastName,
            email: me.email,
            tos: true,
            password:'TRUE', // this is a hack since we always use the affiliate's own password internally
            address: me.address?.addressLine1,
            address_1: me.address?.addressLine1,
            address_2: me.address?.addressLine2,
            city: me.address?.city,
            state: me.address?.state,
            country: me.address?.country,
            zip: me.address?.postalCode,
            phone: me.phone,
            /*
            facebook:fb && fb.url,
            twitter: twitter && twitter.url,
            pinterest: pinterest && pinterest.url,
            tiktok: tiktok && tiktok.url,
            youtube: youtube && youtube.url,
            instagram: instagram && instagram.url,

             */
        }
        return Object.keys(map).filter((item)=>map[item])
    },[data])


    const formFields = useMemo(()=>{
        if(!data) return []
        console.log({data})
        return data
            .siteById
            .signupForm
            .filter(({id})=>alreadyAvailableFields.indexOf(id) < 0)
            .filter((item)=>item.isRequired)
    },[data])
    if (!data) return null;

    return formFields.length > 0 ? <FormRenderer
            values={values}
            isDefaultEnabled
            formFields={formFields}
            onChange={onChange}
        /> : <p className="text-center">Complete the signup by clicking the submit button below</p>
}

export default EnrollModal
