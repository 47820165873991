/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PayoutsPending_item$ref = any;
export type PayoutsPendingQueryVariables = {||};
export type PayoutsPendingQueryResponse = {|
  +pendingPayouts: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +$fragmentRefs: PayoutsPending_item$ref
      |}
    |}>
  |}
|};
export type PayoutsPendingQuery = {|
  variables: PayoutsPendingQueryVariables,
  response: PayoutsPendingQueryResponse,
|};
*/


/*
query PayoutsPendingQuery {
  pendingPayouts {
    edges {
      node {
        ...PayoutsPending_item
      }
    }
  }
}

fragment PayoutsPending_item on PendingPayout {
  site {
    currency
    website
    name
    id
  }
  netAmountPending
  totalAmountPaid
  totalOtherEarnings
  totalSaleEarnings
}
*/

const node/*: ConcreteRequest*/ = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PayoutsPendingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PendingPayoutsConnection",
        "kind": "LinkedField",
        "name": "pendingPayouts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PendingPayoutEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PendingPayout",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PayoutsPending_item"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PayoutsPendingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PendingPayoutsConnection",
        "kind": "LinkedField",
        "name": "pendingPayouts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PendingPayoutEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PendingPayout",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Site",
                    "kind": "LinkedField",
                    "name": "site",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "currency",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "website",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "netAmountPending",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalAmountPaid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalOtherEarnings",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalSaleEarnings",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8c72c4d4f5160048dad4b84f7e5a1f5f",
    "id": null,
    "metadata": {},
    "name": "PayoutsPendingQuery",
    "operationKind": "query",
    "text": "query PayoutsPendingQuery {\n  pendingPayouts {\n    edges {\n      node {\n        ...PayoutsPending_item\n      }\n    }\n  }\n}\n\nfragment PayoutsPending_item on PendingPayout {\n  site {\n    currency\n    website\n    name\n    id\n  }\n  netAmountPending\n  totalAmountPaid\n  totalOtherEarnings\n  totalSaleEarnings\n}\n"
  }
};
// prettier-ignore
(node/*: any*/).hash = 'b9386c63de438c9e0d0dd7aecb489042';

module.exports = node;
