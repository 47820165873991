/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FormFieldTypes = "checkbox" | "country" | "currency" | "date" | "email" | "fileupload" | "multipleSelect" | "password" | "radio" | "select" | "signature" | "text" | "textarea" | "%future added value";
export type EnrollModalQueryVariables = {|
  siteId: number
|};
export type EnrollModalQueryResponse = {|
  +me: ?{|
    +firstName: string,
    +lastName: string,
    +email: any,
    +address: ?{|
      +state: ?string,
      +addressLine1: ?string,
      +addressLine2: ?string,
      +city: ?string,
      +country: ?string,
      +postalCode: ?string,
    |},
    +socials: $ReadOnlyArray<{|
      +url: string
    |}>,
    +website: ?string,
    +companyName: ?string,
  |},
  +siteById: ?{|
    +signupForm: $ReadOnlyArray<{|
      +type: FormFieldTypes,
      +append: ?string,
      +prepend: ?string,
      +id: string,
      +placeholder: ?string,
      +isRequired: boolean,
      +options: ?$ReadOnlyArray<{|
        +label: string,
        +value: string,
      |}>,
      +info: ?string,
      +label: string,
    |}>,
    +name: string,
    +website: string,
  |},
|};
export type EnrollModalQuery = {|
  variables: EnrollModalQueryVariables,
  response: EnrollModalQueryResponse,
|};
*/


/*
query EnrollModalQuery(
  $siteId: Int!
) {
  me {
    firstName
    lastName
    email
    address {
      state
      addressLine1
      addressLine2
      city
      country
      postalCode
    }
    socials {
      url
    }
    website
    companyName
    id
  }
  siteById(referenceId: $siteId) {
    signupForm(requiredFieldsOnly: true) {
      type
      append
      prepend
      id
      placeholder
      isRequired
      options {
        label
        value
      }
      info
      label
    }
    name
    website
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siteId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "address",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "addressLine1",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "addressLine2",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postalCode",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Social",
  "kind": "LinkedField",
  "name": "socials",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "website",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyName",
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "referenceId",
    "variableName": "siteId"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "requiredFieldsOnly",
      "value": true
    }
  ],
  "concreteType": "SignupField",
  "kind": "LinkedField",
  "name": "signupForm",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "append",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prepend",
      "storageKey": null
    },
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "placeholder",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRequired",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Option",
      "kind": "LinkedField",
      "name": "options",
      "plural": true,
      "selections": [
        (v10/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "info",
      "storageKey": null
    },
    (v10/*: any*/)
  ],
  "storageKey": "signupForm(requiredFieldsOnly:true)"
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EnrollModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Profile",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "siteById",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          (v12/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EnrollModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Profile",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "siteById",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          (v12/*: any*/),
          (v6/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9bc50f9f68063515045a1e70c5c36d3a",
    "id": null,
    "metadata": {},
    "name": "EnrollModalQuery",
    "operationKind": "query",
    "text": "query EnrollModalQuery(\n  $siteId: Int!\n) {\n  me {\n    firstName\n    lastName\n    email\n    address {\n      state\n      addressLine1\n      addressLine2\n      city\n      country\n      postalCode\n    }\n    socials {\n      url\n    }\n    website\n    companyName\n    id\n  }\n  siteById(referenceId: $siteId) {\n    signupForm(requiredFieldsOnly: true) {\n      type\n      append\n      prepend\n      id\n      placeholder\n      isRequired\n      options {\n        label\n        value\n      }\n      info\n      label\n    }\n    name\n    website\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '26ec6fc035f88bcdee9033071772f014';

module.exports = node;
