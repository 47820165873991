import React, {useEffect} from 'react';
import Skeleton from "react-loading-skeleton";
import RenderCommissionStructure from "../../../components/app-components/RenderCommissionStructure";
import {Link} from "react-router-dom";
import graphql from 'babel-plugin-relay/macro'
import {useFragment, usePreloadedQuery, useQueryLoader} from "react-relay";

const gql = graphql`
    query StoreDetailsQuery ($siteId: Int){
        mySiteById(referenceId: $siteId){
            site{
                ...StoreDetails_stats
            }
            ...StoreDetails_affiliate
        }
    }
`

function StoreDetails(props) {
    const {storeId} = props.match.params;
    const [queryRef, loader, dispose] = useQueryLoader(gql)
    useEffect(()=>{
        loader({
            siteId: Number(storeId)
        })
        return dispose
    },[storeId])
    if(!queryRef) return null
    return <React.Suspense fallback={<Skeleton count={10}/>}>
        <StatsCard queryRef={queryRef}/>
    </React.Suspense>
}

function StatsCard({queryRef}){
    const data = usePreloadedQuery(gql, queryRef)
    if(!data) return null
    return  <>
        <StatsCardFragment fragmentData={data}/>
        <div>Commission Structure</div>
    </>
}

function StatsCardFragment({fragmentData}){
    console.log(fragmentData)
    const site = useFragment(graphql`
        fragment StoreDetails_stats on Site{
            referenceId
            website
            name
            affiliatePortal
        }
    `, fragmentData.mySiteById.site)
    const affiliate = useFragment(graphql`
        fragment StoreDetails_affiliate on MySite{
            referralCodes
            commissionRate{
                special {
                    product{
                        id
                        name
                    }
                    variation{
                        id
                        name
                    }
                    commission{
                        commissionType,
                        commissionValue
                    }
                    collection{
                        id
                        name
                    }
                }
                standard {
                    commissionType
                    commissionValue
                }
            }
            couponCodes {
                code
            }
        }
    `, fragmentData.mySiteById)

    const {referenceId, website, affiliatePortal} = site;
    const {referralCodes, couponCodes, commissionRate} = affiliate
    return   <><ul className="list-group mb-2">
        <ListItem title={"StoreID"} detail={referenceId}/>
        <ListItem title={"Website"} detail={website}/>
        <ListItem title={"Referral code"} detail={referralCodes.join(",")}/>
        <ListItem title={"Coupon"} detail={couponCodes.map((item)=>item.code).join(",")}/>
        <ListItem title={"Dashboard"} detail={<a target={"_blank"} href={`https://${affiliatePortal}/login-as/${localStorage.getItem('access_token')}`}>Dashboard</a>}/>

    </ul>
        <RenderCommissionStructure commission={commissionRate}/>
        <Link to={"/orders?site_id="+referenceId}>View orders</Link>
        <Link to={"/payouts/history?site_id="+referenceId}>View payouts</Link>
        <Link to={"/traffic?site_id="+referenceId}>View Traffic</Link>

    </>
}

function ListItem({title, detail}){
    return    <li className="list-group-item">
        <strong className={"text-sm d-block"}>{title}</strong>
        {detail}
    </li>
}
export default StoreDetails;
