import React from 'react';
import {FormattedDate} from "react-intl";

function RenderDate({value}) {
    return (
        <FormattedDate value={value} month={"short"} day={"2-digit"} year={"numeric"}/>
    );
}

export default RenderDate;
