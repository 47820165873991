import React from "react";

function Select({value, onChange, options, loading}){

   return <select className={"form-control"} disabled={loading} value={value} onChange={onChange}>
       {
           loading ? <option>Loading...</option> :
           options.map(({value, label})=>{
                return <option value={value} key={String(value)}>{label}</option>
            })
        }
    </select>
}
export default Select;
