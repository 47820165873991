import {CookieStorage} from 'cookie-storage';

const cookieStorage = new CookieStorage();

export function setItem(key, value){
    localStorage.setItem(key, value);
    cookieStorage.setItem(key, value);
}
export function getItem(key){
    return localStorage.getItem(key) || cookieStorage.getItem(key);
}


export function removeItem(key){
    localStorage.removeItem(key);
    cookieStorage.removeItem(key);
}
