import React, {useState} from 'react';
import useInputState from '../../components/hooks/useInputState';
import FormGroup from '../../components/form/FormGroup';
import Modal from '../../components/Modal';
import {fetchGraphQL} from '../../RelayEnvironment';

const changePassword = ({existingPassword, newPassword})=>fetchGraphQL(`
    mutation ChangePasswordMutation($existingPassword: String!, $newPassword: String!){
        updatePassword(existingPassword: $existingPassword, newPassword: $newPassword){
            ...on Error{
                code
                message
            }
            ...on Profile{
                referenceId
            }
        }
    }
`,{
    existingPassword,
    newPassword
}).then(({data})=>data.updatePassword)
function ChangePassword(props) {
    const [visible, setVisible] = useState(false);
    const [existingPassword, setExistingPassword] = useInputState('')
    const [newPassword, setNewPassword] = useInputState('')
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null)
    const [saving, setSaving] = useState(false)
    function onSubmit(e){
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }
        setError(null)
        setSaving(true)
        setSuccess(null)
        changePassword({
                existingPassword,
                newPassword
        }).then((updatePassword)=>{
            setSaving(false)
                if(updatePassword.code){
                    setError(updatePassword.message)
                }else{
                    setSuccess(true)
                }
        })
    }

    return (
        <>  <Modal visible={visible}
                   title={"Change password"}
                   okText={"Submit"}
                   onSubmit={onSubmit}
                   onCancel={()=>setVisible(false)}
                   disabled={!existingPassword || !newPassword}
                   confirmLoading={saving}>
                {
                    error && <p className={"text-danger"}>{error}</p>
                }
                {
                    success && <p className="text-success">Password successfully changed</p>
                }
                <FormGroup label={"Current password"}>
                    <input type="password" value={existingPassword} onChange={setExistingPassword} className={"form-control"}/>
                </FormGroup>
                <FormGroup label={"New password"}>
                    <input type="password" value={newPassword} onChange={setNewPassword} className={"form-control"}/>
                </FormGroup>
        </Modal>
            <button className={"btn btn-link"} onClick={()=>setVisible(true)}>Change password</button>


        </>
    );
}

export default ChangePassword;
