/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type AccountStatus = "Approved" | "Blocked" | "Invited" | "Pending" | "%future added value";
export type DiscountType = "FixedAmount" | "FreeShipping" | "Percentage" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type MyStores_site$ref: FragmentReference;
declare export opaque type MyStores_site$fragmentType: MyStores_site$ref;
export type MyStores_site = {|
  +id: string,
  +site: {|
    +name: string,
    +website: string,
    +domain: string,
    +referenceId: number,
    +affiliatePortal: string,
  |},
  +referralCodes: ?$ReadOnlyArray<string>,
  +couponCodes: ?$ReadOnlyArray<{|
    +code: string,
    +discountType: ?DiscountType,
    +discountValue: ?number,
  |}>,
  +accountStatus: AccountStatus,
  +$refType: MyStores_site$ref,
|};
export type MyStores_site$data = MyStores_site;
export type MyStores_site$key = {
  +$data?: MyStores_site$data,
  +$fragmentRefs: MyStores_site$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MyStores_site",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Site",
      "kind": "LinkedField",
      "name": "site",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "website",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "domain",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "referenceId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "affiliatePortal",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "referralCodes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CouponCode",
      "kind": "LinkedField",
      "name": "couponCodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "discountType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "discountValue",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountStatus",
      "storageKey": null
    }
  ],
  "type": "MySite",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '6bfd520220e3f0365c018be0f31a6c15';

module.exports = node;
