import React, {useCallback, useEffect, useState} from 'react';
import FormGroup from './components/form/FormGroup';
import Button from './components/form/Button';
import useInputState from './components/hooks/useInputState';
import PageTitle from './components/layout/PageTitle';
import {getHttpClient} from './utils/api';
import Skeleton from 'react-loading-skeleton';
import useProfile from './stores/useProfile';

function ContactPage(){
    return <React.Suspense fallback={<Skeleton count={15}/>}>
        <RenderPage />
    </React.Suspense>
}

function RenderPage() {
    const [profile, httpGetProfile] = useProfile(useCallback((state)=>[state.profile, state.httpGetProfile],[]))
    useEffect(()=>{
        if(!profile){
            httpGetProfile()
        }
    },[httpGetProfile, profile])
    const {name, email} = profile || {};
    const [message, setMessage] = useInputState('')
    const [ticket, setTicketNumber] = useState(false);
    const [loading, setLoading] = useState(false);
    function submitMessage(e){
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }
        setLoading(true)
        getHttpClient()
            .post('/site_contact_form',{
                name, email, message
            }).then(({data})=>{
                setLoading(false)
                setTicketNumber(data.ticket)
                setMessage('');
        })
    }

    return (
        <>
            <PageTitle title={"Contact"}/>
                <div className="row">
                    <div className="col-md-6">
                        <p>Have a query, suggestion, feedback? Facing issues with dashboard ?</p>
                        <p>Send us an email at <a target={"_blank"} href={"mailto:admin@goaffpro.com"}>admin@goaffpro.com</a>. Our support team is available 24x7. Or you can use the contact form below. You can also connect with us on <a href={"https://facebook.com/goaffpro/"} target={"_blank"}>Facebook</a> and <a href={"https://twitter.com/goaffpro"}>Twitter</a></p>
                        <hr/>
                        <FormGroup>
                        <span><small>Reply-to: {name} ({email}) </small> </span>
                        </FormGroup>
                        <FormGroup>
                            <textarea cols="30" rows="10" value={message} onChange={setMessage} placeholder={"Enter your message here"} className="form-control"/>
                        </FormGroup>
                        <Button
                            onClick={submitMessage}
                            loading={loading} disabled={message.length < 5 || !email} className={"btn btn-primary"} title={"Send message"}/>
                        {
                            ticket && <p className={"alert alert-success"}>
                                {`We have received your message. Your support ticket no. is #${ticket}. A message acknowledgment is also sent to your email address ${email}`}
                            </p>
                        }
                    </div>
                </div>
        </>
    );
}

export default ContactPage;
