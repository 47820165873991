import graphql from 'babel-plugin-relay/macro';
import Skeleton from 'react-loading-skeleton';
import RenderDate from '../../components/RenderDate';
import React from 'react';
import AgencyHomeQuery from './__generated__/AgencyHomeQuery.graphql';
import {useFragment, usePreloadedQuery} from 'react-relay';
import RenderOrderCommission
    from '../../components/app-components/RenderOrderCommission';
import RenderOrderStatus
    from '../../components/app-components/RenderOrderStatus';

function RecentOrdersGraphQL({query, queryRef}){
    return  <div className={"card"}>
        <div className="card-header">
            Recent Orders
        </div>
        <React.Suspense fallback={<div className="card-body"><Skeleton count={13}/></div>}>
            <Loader queryRef={queryRef} query={query}/>
        </React.Suspense>
    </div>
}

function Loader({query, queryRef}){
    const data = usePreloadedQuery(AgencyHomeQuery, queryRef);
    if(!data) return null;
    return <RenderRecentOrders orders={data}/>
}
function RenderRecentOrders({orders}){
    const data = useFragment(
        graphql`
            fragment RecentOrdersGraphQL_orders on Query {
                orders(first: 5) {
                    edges{
                        node{
                            ...RecentOrdersGraphQL_listItem
                        }
                    }
                }
            }
        `,
        orders
    )
    if(!data) return null
    if(data.orders.edges.length === 0){
        return <div className={"card-body text-center"}>No recent orders</div>
    }
    return <ul className="list-group list-group-flush">

        {
            data?.orders?.edges.map(({node})=><OrderListItem  key={String(node.id)} node={node}/>)
        }
    </ul>
}

function OrderListItem({node}){
    const order = useFragment(graphql`
        fragment RecentOrdersGraphQL_listItem on Order{
            id
            number
            amount
            commission
            createdAt
            currency
            status
            site {
                name,
                website
            }
        }
    `, node)
    return <li key={order.id} className="list-group-item d-flex justify-content-between">
        <div>
            <div>
                <RenderDate value={order.createdAt} />
            </div>
            <small className={"text-muted"}>{order.site.name}</small>
        </div>
        <div className={"text-right"}>
            <RenderOrderCommission status={order.status} currency={order.currency} commission={order.commission}/>
            <div><RenderOrderStatus status={order.status} /></div>
        </div>
    </li>
}

export default RecentOrdersGraphQL
