/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MyStores_site$ref = any;
export type EnrollModalMutationVariables = {|
  siteId: number,
  signupData: any,
|};
export type EnrollModalMutationResponse = {|
  +enroll: {|
    +code?: string,
    +message?: string,
    +$fragmentRefs: MyStores_site$ref,
  |}
|};
export type EnrollModalMutation = {|
  variables: EnrollModalMutationVariables,
  response: EnrollModalMutationResponse,
|};
*/


/*
mutation EnrollModalMutation(
  $siteId: Int!
  $signupData: JSON!
) {
  enroll(siteReferenceId: $siteId, signupData: $signupData) {
    __typename
    ... on MySite {
      ...MyStores_site
    }
    ... on Error {
      code
      message
    }
    ... on Node {
      __isNode: __typename
      id
    }
  }
}

fragment MyStores_site on MySite {
  id
  site {
    name
    website
    domain
    referenceId
    affiliatePortal
    id
  }
  referralCodes
  couponCodes {
    code
    discountType
    discountValue
  }
  accountStatus
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "signupData"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "siteId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "signupData",
    "variableName": "signupData"
  },
  {
    "kind": "Variable",
    "name": "siteReferenceId",
    "variableName": "siteId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "Error",
  "abstractKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EnrollModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "enroll",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MyStores_site"
              }
            ],
            "type": "MySite",
            "abstractKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EnrollModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "enroll",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "site",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "website",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "domain",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "referenceId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "affiliatePortal",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "referralCodes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CouponCode",
                "kind": "LinkedField",
                "name": "couponCodes",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "discountType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "discountValue",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountStatus",
                "storageKey": null
              }
            ],
            "type": "MySite",
            "abstractKey": null
          },
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "95c69b5538b3d4a0a9668ec2677a134a",
    "id": null,
    "metadata": {},
    "name": "EnrollModalMutation",
    "operationKind": "mutation",
    "text": "mutation EnrollModalMutation(\n  $siteId: Int!\n  $signupData: JSON!\n) {\n  enroll(siteReferenceId: $siteId, signupData: $signupData) {\n    __typename\n    ... on MySite {\n      ...MyStores_site\n    }\n    ... on Error {\n      code\n      message\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment MyStores_site on MySite {\n  id\n  site {\n    name\n    website\n    domain\n    referenceId\n    affiliatePortal\n    id\n  }\n  referralCodes\n  couponCodes {\n    code\n    discountType\n    discountValue\n  }\n  accountStatus\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6e874b41aa20018b9d33f671afe27381';

module.exports = node;
