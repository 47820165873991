import React, {Component} from 'react';
import PropTypes from 'prop-types';

//TODO enhance progressbar to be indeterminate when progress = 0 and transform to normal when progress > 0
class RealProgressBar extends Component {


    render() {
        const {max, progress, showProgressLabel} = this.props;
        return (
            <div className="progress" style={{width:"100%"}}>
                <div className="progress-bar progress-bar-animated progress-bar-striped"
                     role="progressbar" style={{width: `${(progress * 100)/max}%`}}
                     aria-valuenow={progress}
                     aria-valuemin="0"
                     aria-valuemax={max}>
                    {
                        showProgressLabel ? `${progress}/${max}` : null
                    }
                </div>

            </div>
        );
    }
}

RealProgressBar.propTypes = {
    max: PropTypes.number,
    progress: PropTypes.number.isRequired,
    showProgressLabel: PropTypes.bool
}

RealProgressBar.defaultProps = {
    max:100,
    showProgressLabel:false
}

export default RealProgressBar;
