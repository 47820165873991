import React, {useCallback, useEffect, useState} from 'react';
import {
    Link,
    NavLink,
    Outlet,
    Route,
    Routes,
    useNavigate,
} from 'react-router-dom';
import AuthIndex from './pages/auth/AuthIndex';
import SettingsIndex from './pages/settings/SettingsIndex';
import ReportsIndex from './pages/reports/ReportsIndex';
import SubAffiliatesIndex from './pages/sub-affiliates/SubAffiliatesIndex';
import OrdersIndex from './pages/orders/OrdersIndex';
import PayoutsIndex from './pages/payouts/PayoutsIndex';
import BillingIndex from './pages/billing/BillingIndex';
import StoresIndex from './pages/stores/StoresIndex';
import AgencyIndex from './pages/agency/AgencyIndex';
import TrafficPage from './pages/traffic/TrafficPage';
import ContactPage from './ContactPage';
import AgencyHome from './pages/dashboard/AgencyHome';
import * as localStorage from './utils/storage';
import Spinner from './components/Spinner';
import GoaffproTopBanner from './components/GoaffproTopBanner';
import {fetchGraphQL} from './RelayEnvironment';
import {
    DashboardIcon,
    DownloadIcon,
    MoneyIcon,
    PeopleIcon,
    SettingsIcon,
    ShoppingIcon,
    TrafficIcon,
} from './components/Icons';
import useProfile from './stores/useProfile';

const access_token = localStorage.getItem('access_token');
function App(){
    return (
        <Routes>
            <Route path={"auth/*"} element={<AuthIndex/>}/>
                <Route element={<DashboardLayout/>}>
                    <Route path={"/*"}>
                        <Route path={"settings"} element={<SettingsIndex/>}/>
                        <Route path={"reports"} element={<ReportsIndex/>}/>
                        <Route path={"sub-affiliates"} element={<SubAffiliatesIndex/>}/>
                        <Route path={"orders"} element={<OrdersIndex/>}/>
                        <Route path={"payouts/*"} element={<PayoutsIndex/>}/>
                        <Route path={"billing"} element={<BillingIndex/>}/>
                        <Route path={"stores/*"} element={<StoresIndex/>}/>
                        <Route path={"agency"} element={<AgencyIndex/>}/>
                        <Route path={"traffic"} element={<TrafficPage/>}/>
                        <Route path={"contact"} element={<ContactPage/>}/>
                        <Route index element={<AgencyHome/>}/>
                </Route>
            </Route>
        </Routes>
    )
}

const DashboardLayout = ()=>{
    const navigate = useNavigate();
    const goto = useCallback((e)=>{
        return navigate(e.target.value)
    },[navigate])
    const [loading, setLoading] = useState(false)
    useEffect(()=>{
        if(access_token) {
            setLoading(true)
            fetchGraphQL(`mutation DashboardMutation ($accessToken: String!){
                refreshAccessToken(accessToken: $accessToken){
                    ...on AccessToken{
                        accessToken
                    }
                    ...on Error{
                        code
                    }
                }
            }`,{
                accessToken: localStorage.getItem('access_token')
            }).then(({data})=>{
                setLoading(false)
                if (!data.refreshAccessToken.accessToken) {
                    //  navigate('/auth/login?redirect_to=' + location.pathname,{replace: true})
                    navigate('/auth/login',{replace: true})
                }
            })
        }
        else{
            navigate('/auth',{replace:true})
        }
        return ()=>{
            console.log('anuj: unmounted')
        }
    },[])
    useEffect(()=>{
        console.log('anuj: navigation rerendered')
    },[navigate])
    if(loading){
        return <div className="text-center" style={{marginTop:200}}><Spinner/></div>
    }


    return (<>
            <GoaffproTopBanner/>
            <main className={"min-vh-100 container-fluid px-0"}>
                <div className="row no-gutters">
                    <div className="min-vh-100 bg-light border-r border col-md-4 col-lg-3 col-xxl-2 d-none d-md-block p-2">
                        <Sidebar />
                        <hr/>
                        <div className="mt-4 m-1 p-2">
                            <h4>Need help?</h4>
                            <p className="text-muted">Got feedback or suggestions ?</p>
                            <Link className={"btn btn-outline-secondary"} to={"/contact"}>Contact us</Link>
                        </div>
                    </div>
                    <div className="col-md-8 col-lg-9 col-xl-9 col-xxl-10">
                        <div className="p-3 border-bottom d-md-none bg-light">
                            <select className="form-control" onChange={goto}>
                                {
                                    SidebarItems.map((item)=>{
                                        return <option key={item.title} value={item.href}>{item.title}</option>
                                    })
                                }
                                <option value={"/contact"}>Contact</option>
                            </select>
                        </div>
                        <div className="px-4 py-3">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

function Sidebar(){
    return <React.Suspense fallback={null}>
        <RenderSidebar/>
    </React.Suspense>
}


function RenderSidebar(){
    const profile = useProfile(useCallback((state)=>state.profile, []))
    return <ul className={"list-group"} style={{minWidth:'100%'}}>{
        SidebarItems.filter((item)=>profile?.agencyId ? true : item.href!=="/agency").map((item)=>{
            return <NavigationItem {...item} key={item.title}/>
        })
    }</ul>


}


function NavigationItem({href, title, Icon, tag}){
    return <NavLink to={href} exact={href === "/"} activeClassName={"sidebar-nav-active"}
                    style={{minWidth:'100%', minHeight:48}}  className={"text-decoration-none sidebar-nav rounded-left py-2 px-2 d-flex justify-content-between align-items-center"}>
        <div className={"d-flex align-items-center"}>
            <Icon style={{fontSize:22}}/><span className={"ml-2"}> {title}</span>
        </div>
        {
            tag && <span className={"badge badge-primary"}>{tag}</span>
        }
    </NavLink>
}

const SidebarItems = [
    {
        title:'Dashboard',
        Icon: DashboardIcon,
        href:'/'
    },
    {
        title:'Stores',
        Icon: ShoppingIcon,
        href:'/stores'
    },
    {
        title:'Orders',
        Icon: ShoppingIcon,
        href:'/orders'
    },
    {
        title:'Traffic',
        Icon: TrafficIcon,
        href:'/traffic'
    }, /*
    {
        title:'Products',
        Icon: ShoppingIcon,
        href:'/products'
    },
    */
    {
        title:'Payouts',
        Icon: MoneyIcon,
        href:'/payouts'
    },

    {
        title:'Agency',
        Icon: PeopleIcon,
        href:'/agency'
    },
    {
        title:'Settings',
        Icon: SettingsIcon,
        href:'/settings'
    },
    {
        title:'Reports',
        Icon: DownloadIcon,
        href:'/reports'
    },

]
export default App
