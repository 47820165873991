/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type RecentPayoutsGraphQL_listItem$ref: FragmentReference;
declare export opaque type RecentPayoutsGraphQL_listItem$fragmentType: RecentPayoutsGraphQL_listItem$ref;
export type RecentPayoutsGraphQL_listItem = {|
  +id: string,
  +createdAt: any,
  +amount: number,
  +note: ?string,
  +paymentMethod: ?string,
  +site: {|
    +name: string,
    +website: string,
    +currency: ?any,
  |},
  +$refType: RecentPayoutsGraphQL_listItem$ref,
|};
export type RecentPayoutsGraphQL_listItem$data = RecentPayoutsGraphQL_listItem;
export type RecentPayoutsGraphQL_listItem$key = {
  +$data?: RecentPayoutsGraphQL_listItem$data,
  +$fragmentRefs: RecentPayoutsGraphQL_listItem$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecentPayoutsGraphQL_listItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "note",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentMethod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Site",
      "kind": "LinkedField",
      "name": "site",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "website",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Payout",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '5469924ac89781f3089254a8b0ce6caa';

module.exports = node;
