import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import RenderFormInput from "./RenderFormInput";

function FormRenderer(props) {
    const {formFields, isDefaultEnabled, onChange, values} = props;
    const [form, setForm] = useState([])
    const [data, setData] = useState({})
    useEffect(()=>{
        setForm(formFields ? formFields.filter(({isEnabled})=>isEnabled === undefined ? isDefaultEnabled : isEnabled) : [])
    }, [formFields])

    const onValueChange = (key) => (e) => {
        setData({...data, [key]:e.target.value})
    }
    useEffect(()=>{
        if(onChange){
            onChange(data)
        }
    }, [data])

    const getValue = useCallback((key)=>{
        if(onChange) {
            return values ? values[key] : props[key];
        }
    },[onChange, values])
    return (
        <>
            <div className={'row flex-wrap'}>
                {
                    form.map((item, index) =>{
                        const key =   item.id+"_"+index
                        const {isRequired, label, placeholder, id, prepend, append, type, size, options} = item;
                        return <RenderFormInput
                                    key={key}
                                    isRequired={isRequired}
                                    label={label}
                                    placeholder={placeholder}
                                    id={id}
                                    prepend={prepend}
                                    append={append}
                                    type={type}
                                    size={size}
                                    options={options}
                                    value={getValue(item.id) || ""}
                                    onChange={onValueChange}
                        />
                    })
                }
            </div>
        </>
    );
}


FormRenderer.propTypes = {
    formFields: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    isDefaultEnabled: PropTypes.bool
}


export default FormRenderer
