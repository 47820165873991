import React, {useEffect, useState} from 'react';
import useQuery from "../../components/hooks/useQuery";
import Skeleton from 'react-loading-skeleton';
import FormGroup from "../../components/form/FormGroup";
import Select from "../../components/form/Select";
import graphql from 'babel-plugin-relay/macro'
import {useFragment, usePreloadedQuery, useQueryLoader} from "react-relay";
import GraphQLLegacyPagination from "../../components/pagination/GraphQLLegacyPagination";
import CopyButton from "../../components/CopyButton";
import {Link} from "react-router-dom";

const q = graphql`
    query MyStoresQuery ($accountStatus: AccountStatus, $offset: Int, $limit: Int){
        mySites(accountStatus: $accountStatus, skip: $offset, first: $limit){
            edges{
                node{
                    id
                    ...MyStores_site
                }
            }
            ...GraphQLLegacyPagination_legacyPageInfo
        }
    }
`
function MyStores(props) {
    const [queryRef, loadQuery, dispose] = useQueryLoader(q);
    const [statusFilter, setStatusFilter] = useState(null);
    const query = useQuery()
    const limit = Number(query.limit || 10);
    const page = Number(query.page || 0);
    useEffect(()=>{
        loadQuery({
            limit: Number(limit),
            offset: Number(page) * Number(limit),
            accountStatus: statusFilter || null
        })
        return dispose
    }, [query.page, query.limit, statusFilter])

    if(!queryRef) return null;

    return (
        <div className={"mt-2"}>
            <div className="bg-light p-2 mb-1">
                <FormGroup label={"Filter by status"}>
                    <Select value={statusFilter} onChange={(e)=>setStatusFilter(e.target.value)}
                        options={[
                            {
                                label:'All accounts',
                                value:''
                            },
                            {
                                label:'Approved accounts',
                                value:'Approved'
                            },
                            {
                                label:'Account pending approval',
                                value: 'Pending'
                            },
                            {
                                label:'Blocked accounts',
                                value: 'Blocked'
                            }
                        ]}
                    />
                </FormGroup>
            </div>
            <React.Suspense fallback={<Skeleton count={limit * 2.5}/>}>
                <RenderPage queryRef={queryRef}/>
            </React.Suspense>
        </div>
    );
}

function RenderPage({queryRef}){
    const data = usePreloadedQuery(q, queryRef)
    if(!data) return null;
    return <>
        <div className={"mb-2"}>
            {
                data.mySites.edges.length === 0 && <p className="mt-4 text-center">
                        No stores found<br/>
                    <Link to={"/stores/browse"}>See available programs</Link>
                </p>
            }
            {
                data.mySites.edges.map(({node}) => {
                    return <StoreItem site={node} key={String(node.id)} />
                })
            }
        </div>
        <GraphQLLegacyPagination fragmentData={data.mySites}  />
    </>

}

function RenderStatus({status}){
    switch (status){
        case "Pending":
            return <span className="badge badge-warning">Pending</span>
        case "Blocked":
            return <span className="badge badge-danger">Blocked</span>
        case "Invited":
            return  <span className="badge badge-info">Invited</span>
        default:
            return null
    }
}


function StoreItem(props){
    const [isOpen, setOpen] = useState(true)
    const data = useFragment(graphql`
        fragment MyStores_site on MySite{
            id
            site {
                name
                website
                domain
                referenceId
                affiliatePortal
            }
            referralCodes
            couponCodes {
                code
                discountType
                discountValue
            }
            accountStatus
        }
    `, props.site)

    if(!data) return null;

    const {website, name, referenceId,domain, affiliatePortal} = data.site
    const {accountStatus, referralCodes, couponCodes, } = data;
    const canShow = ['Approved','Invited'].indexOf(accountStatus) > -1

    return <>
        <div className={`p-2 rounded border mb-2`}>
        <div className="row" onClick={canShow ? ()=>setOpen(!isOpen) : undefined}>
            <div className="col">
                <div>
                    {name}
                    {
                        (!canShow || !isOpen) &&
                        <small className={"d-block text-muted"}>{domain}</small>
                    }
                </div>
            </div>
            <div className="text-right col">
                <small className="text-muted d-block">Store ID: {referenceId}</small>
                <RenderStatus status={accountStatus}/>
            </div>
        </div>
            {
                ["Approved","Invited"].indexOf(accountStatus) > -1 && isOpen ? <>
                    <div className="">
                        <div className="row">
                            <div className="col-md-5">
                                <small className={"d-block"}>
                                    <CopyButton text={`${website}/?ref=${referralCodes[0]}`}
                                                className={"btn text-primary px-0 text-left text-sm"}
                                                copyBtnText={`${domain}/?ref=${referralCodes[0]}`}/>
                                </small>
                            </div>
                            <div className="col-md-3">
                                {
                                    couponCodes && couponCodes.length > 0 ? <>
                                        <small>Coupon codes</small>
                                        {
                                            couponCodes.map(({code}) => {
                                                return <div key={String(code)}>
                                                    <CopyButton text={code} className={"text-primary btn px-0 text-left"}
                                                                copyBtnText={code}/>
                                                </div>
                                            })
                                        }
                                    </> : null
                                }
                            </div>
                            <div className="col-md-4 text-right">
                                <small>
                                    <a title={"Login to dashboard"} target={"_blank"} href={`${affiliatePortal}/login-as/${localStorage.getItem('access_token')}`}>Dashboard</a>
                                </small>
                            </div>
                        </div>
                    </div>
                </> : null
            }
    </div>

        </>

}





export default MyStores;
