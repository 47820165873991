import Loadable from "react-loadable";
import LazyLoadingBar from "../progressbars/ProgressLoader";

const SimpleBarChartLazy =  Loadable({
    loader: () => import('./SimpleBarChart'),
    loading: LazyLoadingBar,
});



export default SimpleBarChartLazy
