import React from 'react'
import Skeleton from "react-loading-skeleton";

function LazyLoadingBar({error, pastDelay}) {
    if (error) {
        return <div>Error! <button className={"btn btn-link text-danger"} onClick={()=>window.location.reload() }>Reload App</button></div>;
    } else if(pastDelay) {
        return <div><Skeleton height={64} /></div>;
    }else{
        return null
    }
}

export default LazyLoadingBar
