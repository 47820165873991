import React, {useEffect} from 'react';
import DashboardCharts from "./DashboardCharts";
import RecentOrdersGraphQL from "./RecentOrdersGraphQL";
import RecentPayoutsGraphQL from "./RecentPayoutsGraphQL";
import graphql from 'babel-plugin-relay/macro';
import {useQueryLoader} from "react-relay";

const homeQuery = graphql`
    query AgencyHomeQuery {
        ...RecentOrdersGraphQL_orders
        ...RecentPayoutsGraphQL_payouts
    }
`
//const queryRef = loadQuery(RelayEnvironment, homeQuery)

export default function AgencyHome(){
    const [queryRef, loadQuery, disposeQuery] = useQueryLoader(homeQuery)
    useEffect(()=>{
        loadQuery();
        return disposeQuery
    },[disposeQuery, loadQuery])
    if(!queryRef) return null;

    return (
      <>
        <h1>Dashboard</h1>
        <DashboardCharts/>
          <div className="row mt-2">
              <div className="col-md-6 mb-2">
                  <RecentOrdersGraphQL query={homeQuery} queryRef={queryRef} />
              </div>
              <div className="col-md-6 mb-2">
                  <RecentPayoutsGraphQL query={homeQuery} queryRef={queryRef} />
              </div>
          </div>
      </>
    )
}
