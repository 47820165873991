import React, {useEffect, useMemo, useState} from 'react';
import useQuery from "../../../components/hooks/useQuery";
import Skeleton from "react-loading-skeleton";
import {CheckLineIcon, CloseIcon, EmptyBoxIcon} from '../../../components/Icons';
import graphql from 'babel-plugin-relay/macro'
import {useFragment, usePreloadedQuery, useQueryLoader} from "react-relay";
import GraphQLLegacyPagination from "../../../components/pagination/GraphQLLegacyPagination";
import RenderCommission from "../../../components/app-components/RenderCommission";
import BrowseStoreFilters from "./BrowseStoreFilters";
import EnrollModal from "./EnrollModal";

const q = graphql`
    query BrowseStoresQuery ($currencies: [Currency!], $search: String, $limit: Int, $skip: Int){
        sites(skip: $skip, currencies: $currencies, search: $search, first: $limit){
            ...GraphQLLegacyPagination_legacyPageInfo
            edges{
                node{
                    id
                    ...BrowseStores_item
                }
            }
        }
    }
`


export default function BrowseStores(){
    const query = useQuery();
    const limit = Number(query.limit || 10);
    const page = Number(query.page || 0);
    const [queryRef, loader, dispose] = useQueryLoader(q)
    useEffect(()=>{
       loader({
           currencies:query.currency ? [query.currency] : undefined,
           search: query.search,
           limit: Number(limit || 10),
           skip: Number(limit || 10) * Number(page || 0)
       })
       return dispose
    },[limit, page, query.search, query.currency])

    if(!queryRef) return null;
    return <>
            <BrowseStoreFilters queryRef={queryRef} />
            <React.Suspense fallback={<Skeleton count={25}/>}>
                <RenderQuery queryRef={queryRef}/>
            </React.Suspense>
        </>
}


function RenderQuery({queryRef}){
    const data = usePreloadedQuery(q, queryRef)
    const [visible, setVisible] = useState(false);
    const enroll = (storeId)=>e=>{
        setVisible(storeId);
    }
    return (
        <>
            <div className="mt-4">
                {
                    data.sites.edges.length <= 0 ? <>
                        <div className="text-center py-4 border rounded">
                                <EmptyBoxIcon className={"text-muted display-4"}/>
                                <div className={"mt-4"}>No results</div></div>
                    </> :<>{
                            data.sites.edges.map(({node}) => {
                                return <BrowseStoreItems node={node} key={node.id} onEnrollClicked={enroll} />
                            })
                            }
                        <GraphQLLegacyPagination fragmentData={data.sites}/>
                    </>
                }
            </div>
            <EnrollModal visible={visible} storeId={visible} onClose={()=>setVisible(false)}/>
        </>
    )
}

function YesOrNo({value}){
    return value ? <CheckLineIcon style={{fontSize:22}} className={"text-success"}/> : <CloseIcon style={{fontSize:22}} className={"text-danger"}/>
}

function renderDays(cookieDuration){
    if(cookieDuration > 86400){
        return Math.round(cookieDuration/86400) + " days"
    }else if(cookieDuration > 3600) {
        return Math.round(cookieDuration/3600) + " hours"
    }else{
        return "Session"
    }
}

function BrowseStoreItems({node, onEnrollClicked}){
    const data = useFragment(graphql`
        fragment BrowseStores_item on Site{
            referenceId
            name
            logo
            currency
            affiliatePortal
            website
            cookieDuration
            areRegistrationsOpen
            isApprovedAutomatically
            commissionRate {
                standard {
                    commissionType
                    commissionValue
                }
            }
        }
    
    `, node)
    const {name, website, affiliatePortal, cookieDuration, referenceId, currency, logo, isApprovedAutomatically, areRegistrationsOpen, commissionRate} = data;
    const u = useMemo(()=>{
        try {
            return website && website.startsWith("http") && website.includes('://') ?
                new URL(website) :
                new URL('https://' + website);
        }catch(e){
            console.log(e)
            return new URL('https://goaffpro.com');
        }
    },[website])
    const [showDetails, setShowDetails] = useState(true);
    return <div className={"border rounded mb-2 p-2"}>
        <div className="row">
            <div className={"col-md-4 text-center text-md-left mb-4 mb-md-0"}>
                <img alt={`${name} logo`} src={logo} style={{maxWidth:200, height:100, objectFit:'contain'}}/>
                <div>
                    {name}
                </div>
                <small>
                    <a href={u.href} target={"_blank"}>{u.host}</a>
                </small>
            </div>
            <div className="col-md-4">
                <div className={"list-group "}>
                    {
                        commissionRate &&
                        <QuickDetailItem title="Commission" desc={
                            <RenderCommission commission_value={commissionRate.standard.commissionValue} commission_type={commissionRate.standard.commissionType}/>}/>
                    }
                    <QuickDetailItem title="Currency" desc={currency}/>
                    <QuickDetailItem title="Cookie Duration" desc={renderDays(cookieDuration)}/>

                    <QuickDetailItem title="Registrations open" desc={<YesOrNo value={areRegistrationsOpen}/>}/>
                    <QuickDetailItem title="Instant access" desc={<YesOrNo value={isApprovedAutomatically}/>}/>
                </div>

            </div>

            <div className={"col-md-4 text-right"}>
                <small className="text-muted">Store ID: {referenceId}</small>
                <div>
                    <button onClick={onEnrollClicked(referenceId)} className={"btn btn-sm btn-outline-primary"}>Enroll</button>
                </div>
                <div>
                    <a href={`${affiliatePortal}`} target={"_blank"}>View program</a>
                </div>
            </div>
        </div>
    </div>


}

function QuickDetailItem({title, desc}){
    return (
        <div className={"list-group-item d-flex justify-content-between"}>
            <small className={"font-weight-bold"}>{title}</small>
            <span>{desc}</span>
        </div>
    )
}
