/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PayoutsPending_item$ref: FragmentReference;
declare export opaque type PayoutsPending_item$fragmentType: PayoutsPending_item$ref;
export type PayoutsPending_item = {|
  +site: {|
    +currency: ?any,
    +website: string,
    +name: string,
  |},
  +netAmountPending: number,
  +totalAmountPaid: number,
  +totalOtherEarnings: number,
  +totalSaleEarnings: number,
  +$refType: PayoutsPending_item$ref,
|};
export type PayoutsPending_item$data = PayoutsPending_item;
export type PayoutsPending_item$key = {
  +$data?: PayoutsPending_item$data,
  +$fragmentRefs: PayoutsPending_item$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PayoutsPending_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Site",
      "kind": "LinkedField",
      "name": "site",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "website",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "netAmountPending",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalAmountPaid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalOtherEarnings",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalSaleEarnings",
      "storageKey": null
    }
  ],
  "type": "PendingPayout",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '1810308ce06843ea7c7c181fe48ffcc8';

module.exports = node;
