import React from "react";
import RenderCommission from "./RenderCommission";

function RenderCommissionStructure({commission}){
    if(!commission) return null;
    const {standard, special, mlm, royalties} = commission;
    return <>
        <ul className="list-group">
            <div className="list-group-item d-flex align-items-center justify-content-between">
                <div>Standard Commission</div>
                <div><RenderCommission commission_value={standard.commission_value} commission_type={standard.commission_type} /></div>
            </div>
        {
            special.map((item)=>{
                return <div key={String(item.id)} className={"list-group-item d-flex align-items-center justify-content-between"}>
                    <span>{item.product.name}</span>
                    <span>
                        <RenderCommission commission_value={item.commission_value} commission_type={item.commission_type}/>
                    </span>
                </div>
            })
        }
        </ul>
    </>
}

export default RenderCommissionStructure
