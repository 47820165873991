import React from 'react';

function RenderOrderStatus({status}){
    if(status === "REJECTED") {
        return <small className="badge badge-danger">{status}</small>
    }else if(status === "PENDING"){
        return <small className="badge badge-warning">{status}</small>
    }else {
        return <small className="badge badge-success">{status}</small>
    }
}

export default RenderOrderStatus;
