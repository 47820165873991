/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DashboardCharts_chart$ref = any;
type DashboardCharts_statscards$ref = any;
export type DashboardChartsQueryVariables = {|
  startDate?: ?any,
  endDate?: ?any,
|};
export type DashboardChartsQueryResponse = {|
  +activityStats: ?{|
    +$fragmentRefs: DashboardCharts_chart$ref & DashboardCharts_statscards$ref
  |}
|};
export type DashboardChartsQuery = {|
  variables: DashboardChartsQueryVariables,
  response: DashboardChartsQueryResponse,
|};
*/


/*
query DashboardChartsQuery(
  $startDate: Date
  $endDate: Date
) {
  activityStats(startDate: $startDate, endDate: $endDate) {
    ...DashboardCharts_chart
    ...DashboardCharts_statscards
  }
}

fragment DashboardCharts_chart on ActivityStats {
  dailyStats {
    date
    commissionTotal
    revenueTotal
    ordersCount
    clickCount
  }
}

fragment DashboardCharts_statscards on ActivityStats {
  aggregate {
    commissionTotal
    ordersCount
    clickCount
    revenueTotal
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v2 = [
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "endDate"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "startDate"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "commissionTotal",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revenueTotal",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ordersCount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clickCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardChartsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ActivityStats",
        "kind": "LinkedField",
        "name": "activityStats",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DashboardCharts_chart"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DashboardCharts_statscards"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DashboardChartsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ActivityStats",
        "kind": "LinkedField",
        "name": "activityStats",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DailyActivityItem",
            "kind": "LinkedField",
            "name": "dailyStats",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "date",
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AggregatedStats",
            "kind": "LinkedField",
            "name": "aggregate",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f98cb41fe8454875582f1ecb8e62a0d0",
    "id": null,
    "metadata": {},
    "name": "DashboardChartsQuery",
    "operationKind": "query",
    "text": "query DashboardChartsQuery(\n  $startDate: Date\n  $endDate: Date\n) {\n  activityStats(startDate: $startDate, endDate: $endDate) {\n    ...DashboardCharts_chart\n    ...DashboardCharts_statscards\n  }\n}\n\nfragment DashboardCharts_chart on ActivityStats {\n  dailyStats {\n    date\n    commissionTotal\n    revenueTotal\n    ordersCount\n    clickCount\n  }\n}\n\nfragment DashboardCharts_statscards on ActivityStats {\n  aggregate {\n    commissionTotal\n    ordersCount\n    clickCount\n    revenueTotal\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ac26cc5695efefadd2bdacff684db013';

module.exports = node;
