import React, {useCallback, useEffect, useState} from 'react';
import Modal from '../../components/Modal';
import FormGroup from '../../components/form/FormGroup';
import useProfile from '../../stores/useProfile';
import {fetchGraphQL} from '../../RelayEnvironment';

function updateEmailAddress({email}){
    return fetchGraphQL(`
    mutation ChangeEmailAddressMutation($email: EmailAddress!){
        updateEmailAddress(newEmailAddress: $email){
            ...on Error{
                code
                message
            }
            ...on Profile{
                email
            }
        }
    }`,{
        email
    }).then(({data})=>data.updateEmailAddress)
}

function ChangeEmailAddress() {
    const [visible, setVisible] = useState(false);
    const [httpGetProfile, e] = useProfile(useCallback((state)=>[state.httpGetProfile, state.profile.email],[]))

    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null)
    const [saving, setSaving] = useState(false)
    useEffect(()=>{
        if(e){
            setEmail(e)
        }
    },[e])

    function updateEmail(e){
        if(e){
            e.preventDefault();
            e.stopPropagation()
        }
        setError(null)
        setSuccess(null)
        setSaving(true)
        updateEmailAddress({
            email
        }).then((updateEmailAddress)=>{
            setSaving(false)
            if(updateEmailAddress.code){
                setError(updateEmailAddress.message)
            }else{
               return httpGetProfile()
            }
        })
    }

    return (
        <>
            <Modal visible={visible}
                   title={"Change email address"}
                   okText={"Update"}
                   onSubmit={updateEmail}
                   disabled={e  === email}
                   onCancel={()=>setVisible(false)}
                  confirmLoading={saving}>
                <FormGroup label={"New email address"}>
                    <input type="email" name={"email"} value={email} onChange={(e)=>setEmail(e.target.value)} className="form-control"/>
                </FormGroup>
                {
                    error && <p className={"text-danger"}>{error}</p>
                }
                {
                    success && <p className="text-success">Email address changed</p>
                }
            </Modal>
            <button className={"btn btn-link"} onClick={()=>setVisible(true)}>Change email address</button>

        </>
    );
}

export default ChangeEmailAddress;
