import React from 'react';

export default ({children})=>{
    return   <div className={"bg-light"}>
        <div className={"container-fluid "}>
            <div className={"row justify-content-center min-vh-100"} style={{paddingTop:'80px'}}>
                <div className={"col-md-6 col-lg-4"}>
                    {children}
                </div>
            </div>
        </div>
    </div>
}
