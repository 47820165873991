/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type DashboardCharts_chart$ref: FragmentReference;
declare export opaque type DashboardCharts_chart$fragmentType: DashboardCharts_chart$ref;
export type DashboardCharts_chart = {|
  +dailyStats: $ReadOnlyArray<{|
    +date: ?any,
    +commissionTotal: number,
    +revenueTotal: number,
    +ordersCount: number,
    +clickCount: number,
  |}>,
  +$refType: DashboardCharts_chart$ref,
|};
export type DashboardCharts_chart$data = DashboardCharts_chart;
export type DashboardCharts_chart$key = {
  +$data?: DashboardCharts_chart$data,
  +$fragmentRefs: DashboardCharts_chart$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DashboardCharts_chart",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DailyActivityItem",
      "kind": "LinkedField",
      "name": "dailyStats",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "commissionTotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "revenueTotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ordersCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clickCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ActivityStats",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'ccfbc6e9e4a2f5103982c872bdbe5912';

module.exports = node;
