/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type OrderStatus = "APPROVED" | "PENDING" | "REJECTED" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type AgencyOrdersGraphQL_orderItem$ref: FragmentReference;
declare export opaque type AgencyOrdersGraphQL_orderItem$fragmentType: AgencyOrdersGraphQL_orderItem$ref;
export type AgencyOrdersGraphQL_orderItem = {|
  +createdAt: any,
  +amount: number,
  +currency: string,
  +commission: number,
  +number: string,
  +status: OrderStatus,
  +site: {|
    +website: string,
    +name: string,
  |},
  +$refType: AgencyOrdersGraphQL_orderItem$ref,
|};
export type AgencyOrdersGraphQL_orderItem$data = AgencyOrdersGraphQL_orderItem;
export type AgencyOrdersGraphQL_orderItem$key = {
  +$data?: AgencyOrdersGraphQL_orderItem$data,
  +$fragmentRefs: AgencyOrdersGraphQL_orderItem$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AgencyOrdersGraphQL_orderItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commission",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Site",
      "kind": "LinkedField",
      "name": "site",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "website",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '3de39b9a3cd2a1f6630bbecfc8102465';

module.exports = node;
