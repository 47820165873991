import React, {useCallback, useEffect, useMemo, useState} from 'react';
import SettingsSection from '../../../components/layout/SettingsSection';
import FormGroup from '../../../components/form/FormGroup';
import {areObjectEqual} from '../../../utils/func';
import Button from '../../../components/form/Button';
import CountrySelector from '../../../components/form/CountrySelector';
import useProfile from '../../../stores/useProfile';

function AddressSection() {
    const [profile, httpUpdateProfile] = useProfile(useCallback((state)=>[state.profile, state.httpUpdateProfile],[]))

    const addr = useMemo(()=>{
        return profile.address || {};
    },[profile.address])
    const [address, setAddress] = useState({})
    useEffect(()=>{
        setAddress(addr)
    },[addr])
    const [saving, setSaving] = useState(false)

    const onChange = (key)=>e=>{
        setAddress({...address, [key]:e.target.value})
    }
    const hasChanged = ()=>{
        return !areObjectEqual(['addressLine1','addressLine2','city','state','postalCode','country'], address, addr)
    }

    const onSubmit = useCallback((e)=>{
        if(e){
            e.preventDefault();
            e.stopPropagation()
        }
        setSaving(true)
        httpUpdateProfile({
            address
        }).then(()=>{
            setSaving(false)
        })
    },[address, httpUpdateProfile])
    const {
        addressLine1,
        addressLine2,
        city,
        state,
        country,
        postalCode
    } = address || {};
    return (
        <SettingsSection title={"Address"} subtitle={"Your address"}>
            <form onSubmit={onSubmit}>
            <FormGroup label={'Address line 1'}>
                <input type="text" name={"address-line1"} value={addressLine1} onChange={onChange('addressLine1')}  className="form-control"/>
            </FormGroup>
            <FormGroup label={'Address line 2'}>
                <input type="text" name={"address-line2"} value={addressLine2} onChange={onChange('addressLine2')}  className="form-control"/>
            </FormGroup>
                <div className="row">
                    <div className="col-6">
                        <FormGroup label={"City"}>
                            <input type="text" name={"city"} value={city} onChange={onChange('city')}  className="form-control"/>
                        </FormGroup>
                    </div>
                    <div className="col-6">
                        <FormGroup label={"State/Province"}>
                            <input type="text" name={"state"} value={state} onChange={onChange('state')}  className="form-control"/>
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <FormGroup label={"Postal code"}>
                            <input type="text" name={"postal-code"} value={postalCode} onChange={onChange('postalCode')}  className="form-control"/>
                        </FormGroup>
                    </div>
                    <div className="col-6">
                        <FormGroup label={"Country"}>
                            <CountrySelector onChange={onChange('country')} value={country}/>
                        </FormGroup>
                    </div>
                </div>

            {
                hasChanged() ? <Button onClick={onSubmit} loading={saving} className={"btn btn-primary"} title={"Submit"}/> : null
            }
            </form>
        </SettingsSection>
    );
}

export default AddressSection;
