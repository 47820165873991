/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type CommissionRateType = "FixedAmountOnOrder" | "FlatRate" | "Percentage" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type BrowseStores_item$ref: FragmentReference;
declare export opaque type BrowseStores_item$fragmentType: BrowseStores_item$ref;
export type BrowseStores_item = {|
  +referenceId: number,
  +name: string,
  +logo: string,
  +currency: ?any,
  +affiliatePortal: string,
  +website: string,
  +cookieDuration: number,
  +areRegistrationsOpen: boolean,
  +isApprovedAutomatically: boolean,
  +commissionRate: {|
    +standard: {|
      +commissionType: CommissionRateType,
      +commissionValue: number,
    |}
  |},
  +$refType: BrowseStores_item$ref,
|};
export type BrowseStores_item$data = BrowseStores_item;
export type BrowseStores_item$key = {
  +$data?: BrowseStores_item$data,
  +$fragmentRefs: BrowseStores_item$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BrowseStores_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "referenceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "affiliatePortal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "website",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cookieDuration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "areRegistrationsOpen",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isApprovedAutomatically",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SiteCommissionRate",
      "kind": "LinkedField",
      "name": "commissionRate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CommissionRate",
          "kind": "LinkedField",
          "name": "standard",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "commissionType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "commissionValue",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'f7d6b4142f30d33b11e6e588073cc804';

module.exports = node;
