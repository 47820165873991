import React, {useMemo} from 'react';
import {useTable} from "react-table";


export default function BasicTable({columns, data, className, onRowClick}){
    const defaultColumn = useMemo(
        () => ({
            canSort:false,
        }),
        []
    );
    const tableInstance = useTable({
        columns,
        defaultColumn,
        data,
    });
    const {
        rows,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
    } = tableInstance

    const onRowClicked = onRowClick ? (index)=>()=>{
        return onRowClick(index)
    }: undefined

    return <>
        <table className={`table table-striped table-bordered ${className}`} {...getTableProps()}>
            <thead>
            {
                headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {
                            headerGroup.headers.map(column=>(
                                <th {...column.getHeaderProps()}>
                                    <div>
                                        {column.render('Header')}
                                    </div>
                                </th>
                            ))
                        }
                    </tr>
                ))
            }
            </thead>
            <tbody {...getTableBodyProps()}>
            {
                data.length > 0 ? <>
                    {// Loop over the table rows
                        rows.map((row, index) => {
                            // Prepare the row for display
                            prepareRow(row)
                            return (
                                // Apply the row props
                                <tr {...row.getRowProps()} onClick={onRowClick ? onRowClicked(index) : undefined}>
                                    {// Loop over the rows cells
                                        row.cells.map(cell => {
                                            // Apply the cell props
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {// Render the cell contents
                                                        cell.render('Cell')
                                                    }
                                                </td>
                                            )
                                        })}
                                </tr>
                            )
                        })}
                </> : <tr>
                    <td colSpan={columns.length} className={"text-center"}>No Data</td>
                </tr>
            }
            </tbody>
        </table>
    </>
}
