/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PayoutsHistory_ListItem$ref: FragmentReference;
declare export opaque type PayoutsHistory_ListItem$fragmentType: PayoutsHistory_ListItem$ref;
export type PayoutsHistory_ListItem = {|
  +id: string,
  +site: {|
    +name: string,
    +website: string,
  |},
  +createdAt: any,
  +amount: number,
  +currency: ?any,
  +note: ?string,
  +paymentMethod: ?string,
  +paymentDetails: ?any,
  +$refType: PayoutsHistory_ListItem$ref,
|};
export type PayoutsHistory_ListItem$data = PayoutsHistory_ListItem;
export type PayoutsHistory_ListItem$key = {
  +$data?: PayoutsHistory_ListItem$data,
  +$fragmentRefs: PayoutsHistory_ListItem$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PayoutsHistory_ListItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Site",
      "kind": "LinkedField",
      "name": "site",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "website",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "note",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentMethod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentDetails",
      "storageKey": null
    }
  ],
  "type": "Payout",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '2b31a18164cce1888779c82af35fedc0';

module.exports = node;
