import React from 'react';
import BrowseStores from "./browse/BrowseStores";
import {Route, Routes, Outlet} from "react-router-dom";
import MyStores from "./MyStores";
import PageTitle from "../../components/layout/PageTitle";
import StoreDetailsIndex from "./StoreDetailsIndex";
import RenderNavigationTabs from '../../components/RenderNavigationTabs';

function StoresIndex(props) {
    return (
        <Routes>
            <Route element={<Container />}>
                <Route path={"browse"} element={<BrowseStores/>}/>
                <Route path={"details/:storeId"} element={<StoreDetailsIndex/>}/>
                <Route index element={<MyStores/>}/>
            </Route>
        </Routes>
    );
}

function Container(){
    return <>
        <PageTitle title={"Stores"}/>
        <RenderNavigationTabs options={[
            ['',"My Stores",true],
            ['browse','Available Stores']
        ]} />
        <Outlet />
    </>
}

export default StoresIndex;
