/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type OrderStatus = "APPROVED" | "PENDING" | "REJECTED" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type RecentOrdersGraphQL_listItem$ref: FragmentReference;
declare export opaque type RecentOrdersGraphQL_listItem$fragmentType: RecentOrdersGraphQL_listItem$ref;
export type RecentOrdersGraphQL_listItem = {|
  +id: string,
  +number: string,
  +amount: number,
  +commission: number,
  +createdAt: any,
  +currency: string,
  +status: OrderStatus,
  +site: {|
    +name: string,
    +website: string,
  |},
  +$refType: RecentOrdersGraphQL_listItem$ref,
|};
export type RecentOrdersGraphQL_listItem$data = RecentOrdersGraphQL_listItem;
export type RecentOrdersGraphQL_listItem$key = {
  +$data?: RecentOrdersGraphQL_listItem$data,
  +$fragmentRefs: RecentOrdersGraphQL_listItem$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecentOrdersGraphQL_listItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commission",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Site",
      "kind": "LinkedField",
      "name": "site",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "website",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'e4c96e1c7698be96ffb9a8f6702a19cf';

module.exports = node;
