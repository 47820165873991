/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BrowseStoreFiltersQueryVariables = {||};
export type BrowseStoreFiltersQueryResponse = {|
  +sitesAvailableCountries: $ReadOnlyArray<string>,
  +sitesAvailableCurrencies: $ReadOnlyArray<any>,
|};
export type BrowseStoreFiltersQuery = {|
  variables: BrowseStoreFiltersQueryVariables,
  response: BrowseStoreFiltersQueryResponse,
|};
*/


/*
query BrowseStoreFiltersQuery {
  sitesAvailableCountries
  sitesAvailableCurrencies
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sitesAvailableCountries",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "sitesAvailableCurrencies",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BrowseStoreFiltersQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BrowseStoreFiltersQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "9a0b3fbe0d470a14ff71a3100c210801",
    "id": null,
    "metadata": {},
    "name": "BrowseStoreFiltersQuery",
    "operationKind": "query",
    "text": "query BrowseStoreFiltersQuery {\n  sitesAvailableCountries\n  sitesAvailableCurrencies\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b95f11fe82ec37894073202889c088a8';

module.exports = node;
