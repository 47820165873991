import React, {useEffect, useState} from "react";

import {useIntl} from "react-intl";
import LazyRangePicker from "../../components/datepickers/LazyRangePicker";
import FormGroup from "../../components/form/FormGroup";
import Skeleton from "react-loading-skeleton";
import graphql from 'babel-plugin-relay/macro';
import {useFragment, usePreloadedQuery, useQueryLoader} from "react-relay";
import Big from "big.js";
import dayjs from "dayjs";
import SimpleBarChartLazy from "../../components/charts/SimpleBarChartLazy";
import useInputState from "../../components/hooks/useInputState";

const query = graphql`
    query DashboardChartsQuery ($startDate: Date, $endDate: Date, $currency: String){
        activityStats(startDate: $startDate, endDate: $endDate, currency: $currency){
            ...DashboardCharts_chart
            ...DashboardCharts_statscards
        }
    }
`
ChartFragment.defaultProps = {
    chartType:"commissionTotal"
}

function ChartFragment({queryData}){
    const intl = useIntl();
    const [chartType, setChartType] = useInputState('commissionTotal');
    const data = useFragment(graphql`
        fragment DashboardCharts_chart on ActivityStats{
            dailyStats{
                date
                commissionTotal
                revenueTotal
                ordersCount
                clickCount
            }
        }
    `, queryData)
    const currentCurrency = 'USD';
    function yFormatter(item){
        switch(chartType){
            case "commissionTotal":
                return intl.formatNumber(item, {
                    style:'currency', currency:currentCurrency,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                })
            case "clickCount":
            case "ordersCount":
                return intl.formatNumber(item)
            default:
                return item;
        }
    }
    return <>
        <div className="mb-1 d-flex align-items-end justify-content-between">
            <PillSelect value={chartType} onChange={setChartType} options={[
                {
                    label:"Earnings",
                    value:'commissionTotal'
                },{
                    label:'Clicks',
                    value:'clickCount'
                }
            ]} />
            {
                chartType === "commissionTotal" ?
                    <small className="text-muted">Earnings are approximate and shown in USD</small> : null
            }
        </div>
        <SimpleBarChartLazy
                tooltipCmp={({active, payload, label}) => {
                    if (!active) return null;
                    if(!payload || !payload[0]) return null
                    const {date, commissionTotal, revenueTotal, ordersCount, clickCount} = payload[0].payload;

                    return (
                        <div className="shadow bg-white p-2 border rounded">
                            <div className="text-sm text-center text-muted mb-2">{intl.formatDate(date, {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                            })}</div>
                            <ul className="list-group list-group-flush">
                                <LiItem name="Traffic" value={intl.formatNumber(clickCount)}/>
                                <LiItem name="Customers" value={intl.formatNumber(ordersCount)}/>
                                <LiItem name="Earnings" value={intl.formatNumber(commissionTotal, {
                                    style: 'currency',
                                    currency: currentCurrency
                                })}/>
                            </ul>
                        </div>
                    );
                }}
                data={data.dailyStats}
                xDataKey={'date'}
                yDataKey={chartType}
                xFormatter={xFormatter}
                yFormatter={yFormatter}
            />
            </>

}
function PillSelect({options, value, onChange}){
    return <div className="d-flex">
        {
            options.map((item)=>{
                return <button
                    style={{minWidth:100}}
                    className={`btn mr-2 btn-sm ${value === item.value ? "btn-primary" :""} rounded-pill`}
                    onClick={(e)=>onChange({target:{value: item.value}})}>
                    {item.label}
                </button>
            })
        }
    </div>
}
function StatsCardDataFragment({queryData}){
    const intl = useIntl();
    const data = useFragment(graphql`
        fragment DashboardCharts_statscards on ActivityStats{
            aggregate{
                commissionTotal
                ordersCount
                clickCount
                revenueTotal
            }
        }
    `, queryData)

    const {clickCount, ordersCount} = data.aggregate;
    const conversion = Big(ordersCount || 0).div(clickCount || 1).times(100).round(2);

    return  <div className="row mt-2">
        <BasicStatsCard title="Clicks" description={intl.formatNumber(data.aggregate.clickCount)}/>
        <BasicStatsCard title="Orders" description={intl.formatNumber(data.aggregate.ordersCount)}/>
        <BasicStatsCard title="Conversion" description={conversion+"%"}/>
        <BasicStatsCard title="Earnings" description={intl.formatNumber(data.aggregate.commissionTotal, {
            style: 'currency',
            currency: 'USD'
        })}/>
    </div>
}

export default function DashboardCharts(){
    const [dates, setDates] = useState([dayjs().subtract(14, 'days').toDate(), dayjs().toDate()])
    const [queryRef, loadQuery, disposeQuery] = useQueryLoader(query)
    useEffect(()=>{
        loadQuery({
            startDate: dates && dates.length > 0 && dates[0] ? dayjs(dates[0]).format('YYYY-MM-DD') : null,
            endDate: dates && dates.length > 0 && dates[0] ? dayjs(dates[1]).format('YYYY-MM-DD') : null
        })
        return disposeQuery
    }, [dates])

    if(!queryRef) return null;

    return (
        <>
            <FormGroup>
                <LazyRangePicker onChange={setDates} value={dates} />
            </FormGroup>
            <React.Suspense fallback={<Skeleton count={17}/>}>
                <RenderData queryRef={queryRef}/>
            </React.Suspense>
        </>
    )
}

function RenderData({queryRef}){
    const data = usePreloadedQuery(query, queryRef)
    if(!data) return;
    return <>

        <ChartFragment queryData={data.activityStats}/>
        <StatsCardDataFragment queryData={data.activityStats}/>
   </>
}


function BasicStatsCard({title, description}){
    return <div className="col-md-3 mb-2 mb-md-0">
           <div className="card">
               <div className="card-body">
                   <h4>{description}</h4>
                   {title}
               </div>
        </div>
    </div>
}

function xFormatter(item){
    return dayjs(item).format('DD-MM')
}

function LiItem({name, value}){
    return (
        <li className="list-group-item d-flex p-2 align-items-center justify-content-between">
            <strong className={"text-sm"}>{name}</strong>
            <span className="text-primary ml-4">{value}</span>
        </li>
    )
}

