/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type StoreDetails_stats$ref: FragmentReference;
declare export opaque type StoreDetails_stats$fragmentType: StoreDetails_stats$ref;
export type StoreDetails_stats = {|
  +referenceId: number,
  +website: string,
  +name: string,
  +affiliatePortal: string,
  +$refType: StoreDetails_stats$ref,
|};
export type StoreDetails_stats$data = StoreDetails_stats;
export type StoreDetails_stats$key = {
  +$data?: StoreDetails_stats$data,
  +$fragmentRefs: StoreDetails_stats$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StoreDetails_stats",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "referenceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "website",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "affiliatePortal",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '497b1a5eb5f049314ec0b3c7662e198a';

module.exports = node;
