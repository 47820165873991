/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PayoutsHistory_ListItem$ref = any;
type PayoutsHistory_pageInfo$ref = any;
export type PayoutsHistoryQueryVariables = {|
  limit: number,
  offset: number,
  siteId?: ?number,
|};
export type PayoutsHistoryQueryResponse = {|
  +payouts: ?{|
    +edges: $ReadOnlyArray<{|
      +node: {|
        +$fragmentRefs: PayoutsHistory_ListItem$ref
      |}
    |}>,
    +$fragmentRefs: PayoutsHistory_pageInfo$ref,
  |}
|};
export type PayoutsHistoryQuery = {|
  variables: PayoutsHistoryQueryVariables,
  response: PayoutsHistoryQueryResponse,
|};
*/


/*
query PayoutsHistoryQuery(
  $limit: Int!
  $offset: Int!
  $siteId: Int
) {
  payouts(first: $limit, skip: $offset, siteReferenceId: $siteId) {
    edges {
      node {
        ...PayoutsHistory_ListItem
        id
      }
    }
    ...PayoutsHistory_pageInfo
  }
}

fragment PayoutsHistory_ListItem on Payout {
  id
  site {
    name
    website
    id
  }
  createdAt
  amount
  currency
  note
  paymentMethod
  paymentDetails
}

fragment PayoutsHistory_pageInfo on GraphQLPagination {
  __isGraphQLPagination: __typename
  legacyPageInfo {
    count
    offset
    limit
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "limit"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "offset"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siteId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "siteReferenceId",
    "variableName": "siteId"
  },
  {
    "kind": "Variable",
    "name": "skip",
    "variableName": "offset"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PayoutsHistoryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PayoutsConnection",
        "kind": "LinkedField",
        "name": "payouts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PayoutsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Payout",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PayoutsHistory_ListItem"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PayoutsHistory_pageInfo"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PayoutsHistoryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PayoutsConnection",
        "kind": "LinkedField",
        "name": "payouts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PayoutsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Payout",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Site",
                    "kind": "LinkedField",
                    "name": "site",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "website",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "note",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "paymentMethod",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "paymentDetails",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LegacyPageInfo",
                "kind": "LinkedField",
                "name": "legacyPageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "count",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "offset",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "limit",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "GraphQLPagination",
            "abstractKey": "__isGraphQLPagination"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c377a0aad7abbde57eaca1a416edb65d",
    "id": null,
    "metadata": {},
    "name": "PayoutsHistoryQuery",
    "operationKind": "query",
    "text": "query PayoutsHistoryQuery(\n  $limit: Int!\n  $offset: Int!\n  $siteId: Int\n) {\n  payouts(first: $limit, skip: $offset, siteReferenceId: $siteId) {\n    edges {\n      node {\n        ...PayoutsHistory_ListItem\n        id\n      }\n    }\n    ...PayoutsHistory_pageInfo\n  }\n}\n\nfragment PayoutsHistory_ListItem on Payout {\n  id\n  site {\n    name\n    website\n    id\n  }\n  createdAt\n  amount\n  currency\n  note\n  paymentMethod\n  paymentDetails\n}\n\nfragment PayoutsHistory_pageInfo on GraphQLPagination {\n  __isGraphQLPagination: __typename\n  legacyPageInfo {\n    count\n    offset\n    limit\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9f7aeea7d7e2b0e7c10b7cedf2931fbe';

module.exports = node;
