import React, {Component} from 'react';
import PropTypes from 'prop-types';

class RadioGroup extends Component {

    onClick  = (value) => (e) => {
        if (e){
            e.preventDefault();
            e.stopPropagation();
        }
        this.props.onChange({ target: { value } });
    }

    render() {
        return (
            <div className={'d-flex flex-wrap'}>
                {
                    this.props.options && this.props.options.map((item, index) => (<span className={'pr-2 mr-2'} key={index}>
						<input type={'radio'} value={item} name={item}  onChange={this.onClick(item)} checked={item === this.props.checked} />
						<label htmlFor={item} className={'pr-4 pl-1'}  onClick={this.onClick(item)}>{item}</label>
					</span>))

                }
            </div>
        );
    }
}

RadioGroup.propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    checked: PropTypes.string.isRequired
};

export default RadioGroup;
