import {NavLink} from 'react-router-dom';

function RenderNavigationTabs({options, className}) {
  return (
      <ul className={`nav nav-tabs ${className || ''}`}>
        {
          options.map(([to,  title, end])=>{
            return  <li className={"nav-item"} key={String(to)}>
              <NavLink to={to} end={end} className={({isActive})=> `nav-link ${isActive ? 'active' : ''}`}>{title}</NavLink>
            </li>
          })
        }
      </ul>
  );
}
export default RenderNavigationTabs;
