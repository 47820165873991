import graphql from "babel-plugin-relay/macro";
import React from 'react';
import CurrencyNumber from "../../components/CurrencyNumber";
import Skeleton from "react-loading-skeleton";
import RenderDate from "../../components/RenderDate";
import {useFragment, usePreloadedQuery} from "react-relay";

function RecentPayouts({query, queryRef}) {
    return (
        <div className={"card"}>
            <div className="card-header">
                Recent Payouts
            </div>
            <React.Suspense fallback={<div className="card-body"><Skeleton count={13}/></div>}>
                <RenderPayoutsList query={query} queryRef={queryRef}/>
            </React.Suspense>
        </div>
    );
}

function RenderPayoutsList({query, queryRef}){
    const data = usePreloadedQuery(query, queryRef)
    console.log('loaded data', data)
   return <RecentPayoutsList payouts={data}/>
}

function RecentPayoutsList({payouts}){
    const data = useFragment(
        graphql`
            fragment RecentPayoutsGraphQL_payouts on Query{
                payouts (first: 5) {
                    edges {
                        node {
                          ...RecentPayoutsGraphQL_listItem
                        }
                    }
                }
            }
        `, payouts
    )
    if(!data) return null;
    if(!data.payouts) return null;
    if(data.payouts.edges.length === 0){
        return <div className={"card-body text-center"}>No recent payouts</div>
    }
    return  <ul className="list-group list-group-flush">
        {
            data?.payouts?.edges.map(({node})=> <PayoutListItem key={String(node.id)} node={node} />)
        }
    </ul>
}

function PayoutListItem({node}){
    const data = useFragment(graphql`
        fragment RecentPayoutsGraphQL_listItem on Payout{
            id
            createdAt
            amount
            note
            paymentMethod
            site{
                name
                website
                currency
            }
        }
    `, node)
    const {amount, site, paymentMethod, createdAt} = data
    return <li className="list-group-item d-flex justify-content-between">
        <div>
            <div>
                <RenderDate value={createdAt}/>
            </div>
            <small className={"text-muted"}>{site.name}</small>
        </div>
        <div className={"text-right"}>
            <div className="text-info">
                <CurrencyNumber currency={site.currency} value={amount}/>
            </div>
            <small className={"d-block text-muted"}>
                {paymentMethod?.toUpperCase()}
            </small>
        </div>
    </li>
}

export default RecentPayouts;
