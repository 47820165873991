/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type GraphQLLegacyPagination_legacyPageInfo$ref: FragmentReference;
declare export opaque type GraphQLLegacyPagination_legacyPageInfo$fragmentType: GraphQLLegacyPagination_legacyPageInfo$ref;
export type GraphQLLegacyPagination_legacyPageInfo = {|
  +legacyPageInfo: {|
    +count: number,
    +offset: number,
    +limit: number,
  |},
  +$refType: GraphQLLegacyPagination_legacyPageInfo$ref,
|};
export type GraphQLLegacyPagination_legacyPageInfo$data = GraphQLLegacyPagination_legacyPageInfo;
export type GraphQLLegacyPagination_legacyPageInfo$key = {
  +$data?: GraphQLLegacyPagination_legacyPageInfo$data,
  +$fragmentRefs: GraphQLLegacyPagination_legacyPageInfo$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GraphQLLegacyPagination_legacyPageInfo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LegacyPageInfo",
      "kind": "LinkedField",
      "name": "legacyPageInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "offset",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "limit",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GraphQLPagination",
  "abstractKey": "__isGraphQLPagination"
};
// prettier-ignore
(node/*: any*/).hash = '85f414f12aba733fd6ea47255a8b36ab';

module.exports = node;
