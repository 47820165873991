import React from 'react';
import Label from "./Label";


function FormGroup ({children, label, required}){
    return <div className="form-group">
        {
            label && <><Label title={label}/>{required && <small className={"ml-1 text-danger"}>*</small>}</>
        }
        {children}
    </div>
}
export default FormGroup;
