/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PayoutsHistory_pageInfo$ref: FragmentReference;
declare export opaque type PayoutsHistory_pageInfo$fragmentType: PayoutsHistory_pageInfo$ref;
export type PayoutsHistory_pageInfo = {|
  +legacyPageInfo: {|
    +count: number,
    +offset: number,
    +limit: number,
  |},
  +$refType: PayoutsHistory_pageInfo$ref,
|};
export type PayoutsHistory_pageInfo$data = PayoutsHistory_pageInfo;
export type PayoutsHistory_pageInfo$key = {
  +$data?: PayoutsHistory_pageInfo$data,
  +$fragmentRefs: PayoutsHistory_pageInfo$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PayoutsHistory_pageInfo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LegacyPageInfo",
      "kind": "LinkedField",
      "name": "legacyPageInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "offset",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "limit",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GraphQLPagination",
  "abstractKey": "__isGraphQLPagination"
};
// prettier-ignore
(node/*: any*/).hash = 'd039fc52d9ce3e3501839e866a40e36c';

module.exports = node;
