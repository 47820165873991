import React from 'react';
import {Outlet, Route, Routes} from 'react-router-dom';
import PayoutsHistory from './PayoutsHistory';
import PayoutsPending from './PayoutsPending';
import PageTitle from '../../components/layout/PageTitle';
import RenderNavigationTabs from '../../components/RenderNavigationTabs';

function PayoutsIndex(props) {
    return (
        <Routes>
            <Route element={<Container/>}>
                <Route path={"history"} element={<PayoutsHistory/>}/>
                <Route index element={<PayoutsPending/>}/>
            </Route>
        </Routes>
    );
}
function Container(){
  return <>
    <PageTitle title={"Payouts"}/>
    <RenderNavigationTabs options={[
        ['','Pending payments', true],
        ['history','Payment history']
    ]} />
    <Outlet />
  </>
}


export default PayoutsIndex;
