import React from 'react';
import currencies from "../../currencies";
import PropTypes from "prop-types";

function CurrencySelector({value, disabled, onChange, showCurrencies}){
        return (
            <select className={'form-control'} disabled={disabled} name={"default_currency"}
                    value={value}
                    onChange={onChange}>
                <option/>
                {
                    Object.keys(currencies)
                            .filter((cur)=>showCurrencies ? showCurrencies.indexOf(cur) > -1 : true).map((cur)=>{
                        return <option key={cur} value={cur}>{currencies[cur].name}</option>
                    })
                }
            </select>
        );
}

CurrencySelector.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
}

export default CurrencySelector;
