/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type StoreDetails_affiliate$ref = any;
type StoreDetails_stats$ref = any;
export type StoreDetailsQueryVariables = {|
  siteId?: ?number
|};
export type StoreDetailsQueryResponse = {|
  +mySiteById: ?{|
    +site: {|
      +$fragmentRefs: StoreDetails_stats$ref
    |},
    +$fragmentRefs: StoreDetails_affiliate$ref,
  |}
|};
export type StoreDetailsQuery = {|
  variables: StoreDetailsQueryVariables,
  response: StoreDetailsQueryResponse,
|};
*/


/*
query StoreDetailsQuery(
  $siteId: Int
) {
  mySiteById(referenceId: $siteId) {
    site {
      ...StoreDetails_stats
      id
    }
    ...StoreDetails_affiliate
    id
  }
}

fragment StoreDetails_affiliate on MySite {
  referralCodes
  commissionRate {
    special {
      product {
        id
        name
      }
      variation {
        id
        name
      }
      commission {
        commissionType
        commissionValue
      }
      collection {
        id
        name
      }
    }
    standard {
      commissionType
      commissionValue
    }
  }
  couponCodes {
    code
  }
}

fragment StoreDetails_stats on Site {
  referenceId
  website
  name
  affiliatePortal
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siteId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "referenceId",
    "variableName": "siteId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  (v2/*: any*/)
],
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "commissionType",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "commissionValue",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StoreDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MySite",
        "kind": "LinkedField",
        "name": "mySiteById",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "site",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "StoreDetails_stats"
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "StoreDetails_affiliate"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StoreDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MySite",
        "kind": "LinkedField",
        "name": "mySiteById",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "site",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "referenceId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "website",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "affiliatePortal",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "referralCodes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SiteCommissionRate",
            "kind": "LinkedField",
            "name": "commissionRate",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SpecialCommissionRates",
                "kind": "LinkedField",
                "name": "special",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "IdAndNamePair",
                    "kind": "LinkedField",
                    "name": "product",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "IdAndNamePair",
                    "kind": "LinkedField",
                    "name": "variation",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CommissionRate",
                    "kind": "LinkedField",
                    "name": "commission",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "IdAndNamePair",
                    "kind": "LinkedField",
                    "name": "collection",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CommissionRate",
                "kind": "LinkedField",
                "name": "standard",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CouponCode",
            "kind": "LinkedField",
            "name": "couponCodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "502352111d8c79c3fd7128767fa4ea9a",
    "id": null,
    "metadata": {},
    "name": "StoreDetailsQuery",
    "operationKind": "query",
    "text": "query StoreDetailsQuery(\n  $siteId: Int\n) {\n  mySiteById(referenceId: $siteId) {\n    site {\n      ...StoreDetails_stats\n      id\n    }\n    ...StoreDetails_affiliate\n    id\n  }\n}\n\nfragment StoreDetails_affiliate on MySite {\n  referralCodes\n  commissionRate {\n    special {\n      product {\n        id\n        name\n      }\n      variation {\n        id\n        name\n      }\n      commission {\n        commissionType\n        commissionValue\n      }\n      collection {\n        id\n        name\n      }\n    }\n    standard {\n      commissionType\n      commissionValue\n    }\n  }\n  couponCodes {\n    code\n  }\n}\n\nfragment StoreDetails_stats on Site {\n  referenceId\n  website\n  name\n  affiliatePortal\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e8336c54699f9585673b16367cd567e4';

module.exports = node;
