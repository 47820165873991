import React, { useState} from 'react';
import CSVReaderInputLazy from "./CSVReaderInputLazy";
import Spinner from "../Spinner";
export default function CsvToObjectInput({onChange}){
    const [loading, setLoading] = useState(false)
    const [csvError, setError] = useState(null)
    function onCSV(data){
        setLoading(false)
        if(data && data.length > 0) {
            const headingArray = data.shift();
            const obj = data.map((item)=>{
                const map = {};
                headingArray.forEach((key, index)=>{
                    map[key.trim()] = item[index].trim()
                })
                return map;
            })
            if(onChange) onChange(obj);
            setError(null)
        }else{
            setError(<p className={"text-danger"}>Invalid CSV File</p>)
        }
    }

    return (<>
        {
            csvError ? <span>{csvError}</span> : null
        }
            {
                loading ? <Spinner/> : null
            }
        <CSVReaderInputLazy onResult={onCSV} />
        </>
    )
}
