import graphql from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import URLBasedPagination from "./URLBasedPagination";
import React from "react";

const legacyPageInfoFragment = graphql`
    fragment GraphQLLegacyPagination_legacyPageInfo on GraphQLPagination {
        legacyPageInfo{
            count
            offset
            limit
        }
    }
`
function GraphQLLegacyPagination({fragmentData}){
    const data = useFragment(legacyPageInfoFragment, fragmentData);
    if(!data) return null
    const {offset, count, limit} = data.legacyPageInfo;
    return  count > 0 ? <div className="mt-2">
        <URLBasedPagination page={Math.ceil(offset/limit)} count={count} limit={limit}/>
    </div> : null
}

export default GraphQLLegacyPagination
