import React, {useCallback} from 'react';
import PhotoUploader from './PhotoUploader';
import useProfile from '../../../stores/useProfile';

function ProfilePhotoUploader() {
    const [profile, httpUpdateProfile] = useProfile(useCallback((state)=>[state.profile, state.httpUpdateProfile],[]))
    const onChange = useCallback((url)=>{
        httpUpdateProfile({
            photo: url
        })
    },[httpUpdateProfile])
    const deleteImage = useCallback(()=>{
        onChange(null)
    },[onChange])



    return (
        <>
            <PhotoUploader value={profile.profilePhoto?.url} onChange={onChange}/>
            {
                profile.profilePhoto?.url && <button type={"button"} className={"btn text-danger px-0"} onClick={deleteImage}>Delete</button>
            }
        </>
    );
}

export default ProfilePhotoUploader;
