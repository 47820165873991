import React from 'react';
import { createRoot } from 'react-dom/client';
import './App.scss';
import reportWebVitals from './reportWebVitals';
import App from "./App";
import {IntlProvider} from "react-intl";
import {RelayEnvironmentProvider} from 'react-relay'
import RelayEnvironment from "./RelayEnvironment";
import Bugsnag from '@bugsnag/js'
import {BrowserRouter} from 'react-router-dom'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
    apiKey: '1e7e3c2e2ab7e01f833e2fa010ea36e3',
    plugins: [new BugsnagPluginReact()]
})
const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React)

const container =   document.getElementById('root')
const root = createRoot(container)
root.render(  <React.StrictMode>
  <RelayEnvironmentProvider environment={RelayEnvironment}>
    <IntlProvider locale={"en"}>
      <ErrorBoundary>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ErrorBoundary>
    </IntlProvider>
  </RelayEnvironmentProvider>
</React.StrictMode>)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
