import React, {useState} from 'react';
import PageTitle from "../../components/layout/PageTitle";
import Modal from "../../components/Modal";
import CsvToObjectInput from "../../components/csvinput/CSVToObjectInput";
import FormGroup from "../../components/form/FormGroup";
import Button from "../../components/form/Button";
import {saveAs} from "file-saver";

function AgencyAffiliates(props) {
    const [importModal, showImportModal] = useState(false);

    function onModalClose(result){
        if(result){
            //reload agency affiliates
        }
        showImportModal(false)
    }

    return (
        <>
            <PageTitle title={"Affiliates"} subtitle={"Affiliates enrolled in your agency"}/>
            <ImportAffiliatesModal visible={importModal} onClose={onModalClose} />
            <div className="text-right"><Button className={"btn btn-link"} onClick={()=>showImportModal(true)}>Add affiliates</Button></div>
        </>
    );
}

function ImportAffiliatesModal({visible, onClose}){
    const [data, setData] = useState([])

    function onCSV(obj){
        setData(obj.map((item)=>{
            return {
                name: item.Name,
                email: item.Email,
                referralCode: item['Sub ID'],
                fees: item['Fee (%)'],
            }
        }))
    }

    function createAffiliates(){

    }
    function sampleCsv(){
        const csvHeadings = ['Name,Email,Referral Code,Fee (%)'];
        const csvData = [
            ['John Doe', 'affiliateemail@example.com', 'jdoe','10'].join(",")
        ]

        csvHeadings.push(...csvData);
        const csv = csvHeadings.join("\r\n");
        const blob = new Blob([csv], {type: "text/csv;charset=utf-8"});
        saveAs(blob, `agency-affiliate-import-sample.csv`);
    }
    return <Modal
        title={"Bulk import affiliates"}
        visible={visible} onClose={()=>onClose(false)} onCancel={data.length > 0 ? ()=>onClose(false) : undefined} okText={"Import"} onSubmit={data.length > 0 ? createAffiliates : undefined}>
        <p>Upload a CSV file in the following format to bulk create the affiliates. <button className={"btn btn-link d-block px-0"} onClick={sampleCsv}>Download sample CSV</button> </p>
        <FormGroup>
            <CsvToObjectInput onChange={onCSV} />
        </FormGroup>
        {
            data.length > 0 ? <>
            <p className={"text-center"}>Affiliates to import: <strong>{data.length}</strong></p>
                <table className={"table table-sm table-bordered"}>
                    <thead>
                        <tr>
                            <th>Affiliate</th>
                            <th>Referral Code</th>
                            <th>Fees (%)</th>
                        </tr>
                    </thead>
             <tbody>
            {
                data.slice(0, 10).map((item)=>{
                    return  <tr key={String(item.email)}>
                        <td>{item.name}<br/><small>{item.email}</small></td>
                        <td>{item.referralCode}</td>
                        <td>{item.fees}%</td>
                    </tr>
                })
            }
             </tbody>
            </table>
            </>
            : null
        }

    </Modal>


}

export default AgencyAffiliates;
