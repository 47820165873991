import React, {useCallback, useState} from 'react';
import {useDropzone} from "react-dropzone";
import {useMutation} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import Spinner from "../../../components/Spinner";

const mutation = graphql`
    mutation PhotoUploaderMutation ($fileName: String!) {
        getPreSignedUrl(fileName: $fileName){
            url
            fields
        }
    }
`
function PhotoUploader({value, onChange}) {

    const [commit, isInFlight] = useMutation(mutation)
    const [loading, setLoading] = useState(false)
    const onDrop = useCallback(acceptedFiles => {
        const file = acceptedFiles[0];
        setLoading(true);
        commit({
            variables:{
                fileName: file.name,
            },
            onCompleted({getPreSignedUrl}){
                const {url, fields} = getPreSignedUrl;
                const formData = new FormData();
                Object.keys(fields).forEach((item)=>{
                    formData.append(item, fields[item])
                })
                formData.append("file", file)
                fetch(url, {
                    method:'POST',
                    body: formData
                }).then((data)=>data.status === 204)
                    .then((result)=>{
                        setLoading(false)
                        onChange({url: result ? `https://${fields.bucket}/${fields.key}` : null})
                })
            }
        })
    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        maxFiles:2,
        accept:'image/jpeg, image/png, image/jpg',
        maxSize: 5 * 1024 * 1024
    })
    return (
        <div {...getRootProps()} className={`border rounded ${isDragActive ? 'bg-light':''}`}>
            <input {...getInputProps()} />
            <div className="p-2">
                {
                    loading ? <div className={"text-center"}><Spinner/></div> : <>
                        {
                            value ? <img alt={"Profile photo"}
                                         src={value}
                                         style={{borderRadius:50, width: 100, height: 100, objectFit: 'cover'}}/>:  <>{
                                isDragActive ?
                                    <span>Drop the files here ...</span> :
                                    <span>Drag 'n' drop some files here, or click to select files</span>
                            }</>
                        }

                    </>
                }
            </div>
        </div>
    );
}

export default PhotoUploader;
