import React from 'react';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import PropTypes from 'prop-types';
import Button from "./form/Button";

export default function({confirmLoading, onOk, onCancel, onClose, okText, cancelText, title, visible, onSubmit, children, size, disabled}){
    return (
        <Modal isOpen={visible} toggle={onCancel || onClose} size={size}>
            {
                (title) &&
                <ModalHeader toggle={onCancel || onClose}>{title}</ModalHeader>
            }
            <Form onSubmit={onSubmit}>
                <ModalBody>
                    {children}
                </ModalBody>
                {
                    (onCancel || onSubmit || onOk || onCancel) &&
                    <ModalFooter>
                        {
                            onCancel ?
                                <button type={"button"} className={"btn btn-outline mr-1"}
                                        onClick={onCancel}>{cancelText || "Cancel"}</button> :
                                null
                        }
                        {
                            onSubmit || onOk || onCancel ?
                                <Button loading={confirmLoading} type={"submit"} className={"btn btn-primary"} disabled={disabled}
                                        onClick={onSubmit || onOk || onCancel}>{okText || "OK"}</Button> : null
                        }
                    </ModalFooter>
                }
            </Form>
        </Modal>
    );
}


function Form({onSubmit, children}){
    return onSubmit ? <form onSubmit={onSubmit}>{children}</form> : children
}


Modal.propTypes = {
    title: PropTypes.string,
    visible:PropTypes.bool,
    onClose: PropTypes.func,
    onCancel:PropTypes.func,
    onSubmit: PropTypes.func,
    confirmLoading: PropTypes.bool,
    onOk: PropTypes.func,
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'sm'])
}
