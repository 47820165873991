import useQuery, {appendToQuery} from "../../../components/hooks/useQuery";
import React, {useEffect, useState} from "react";
import FormGroup from "../../../components/form/FormGroup";
import InputGroup from "../../../components/form/InputGroup";
import Button from "../../../components/form/Button";
import CurrencySelector from "../../../components/form/CurrencySelector";
import CountrySelector from "../../../components/form/CountrySelector";
import {useNavigate, useLocation} from "react-router-dom";
import graphql from 'babel-plugin-relay/macro'
import {usePreloadedQuery, useQueryLoader} from "react-relay";
import Skeleton from "react-loading-skeleton";

const q = graphql`
    query BrowseStoreFiltersQuery{
        sitesAvailableCountries
        sitesAvailableCurrencies
    }
`
function BrowseStoreFilters(){
    const [queryRef, loader, dispose] = useQueryLoader(q);
    useEffect(()=>{
        loader()
        return dispose
    },[])

    if(!queryRef) return null;
    return <React.Suspense fallback={<Skeleton count={12}/>}>
        <RenderFilter queryRef={queryRef}/>
    </React.Suspense>

}


function RenderFilter({queryRef}){
    const data = usePreloadedQuery(q, queryRef)
    const query = useQuery();
    const navigate = useNavigate();
    const [currency, setCurrency] = useState('');
    const [country, setCountry] = useState('');
    const [search, setSearch] = useState(query.search);
    const location = useLocation();
    function initiateSearch(e){
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }
        navigate(appendToQuery(location, {search}))
    }

    useEffect(()=>{
        setCountry(query.country)
    }, [query.country])


    useEffect(()=>{
        setCurrency(query.currency)
    }, [query.currency])

    useEffect(()=>{
        setSearch(query.search)
    }, [query.search])

    return (
        <div className="bg-light p-4">
            <FormGroup label={"Search for programs"}>
                <form onSubmit={initiateSearch} className="row">
                    <div className="col">
                        <InputGroup type="text" value={search} onChange={(e)=>setSearch(e.target.value)} />
                        <small className="text-muted">eg. Clothing, Sports, Beauty products etc.</small>
                    </div>
                    <div className="col-auto">
                        <Button onClick={initiateSearch} className="btn btn-primary" title={"Search"}/>
                    </div>
                </form>
            </FormGroup>
            <div className="row">
                <div className="col">
                    <FormGroup label={"Currency"}>
                        <CurrencySelector
                            showCurrencies={data.sitesAvailableCurrencies}
                            value={currency} onChange={(e)=>navigate(appendToQuery(location, {currency: e.target.value}))} />
                    </FormGroup>
                </div>
                <div className="col">
                    <FormGroup label={"Ships to"}>
                        <CountrySelector value={country} onChange={(e)=>navigate(appendToQuery(location, {country: e.target.value}))}/>
                    </FormGroup>
                </div>
            </div>
        </div>
    )
}

export default BrowseStoreFilters
